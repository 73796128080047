import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Image, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DateRangePicker, Modal } from "rsuite";
import { Button, Checkbox, Divider, Form, Icon, List, ListContent, ListIcon, ListItem, Popup } from "semantic-ui-react";
import { Toast } from "../component/Toast";
import moment from "moment";

export const Raffle = () => {
    const [dataRaffle, setDataRaffle] = useState([])
    const navigate = useNavigate()
    const [desc, setDesc] = useState('')
    const [image, setImage] = useState([])
    const dateNow = new Date();
    const [alloc, setAlloc] = useState('A')
    const [active, setActive] = useState('Y')
    const [showEdit, setShowEdit] = useState(false)
    const [selId, setSelId] = useState('')
    const [editLoad, setEditLoad] = useState(false)
    const [addLoad, setAddLoad] = useState(false)
    const [delLoad, setDelLoad] = useState({})
    const [showModalInfo, setShowModalInfo] = useState(false)
    const [selRaffleData, setSelRaffleData] = useState(null)
    const [rafflePrt, setRafflePrt] = useState([])
    const [productRaffle, setProductRaffle] = useState([])
    const timeNow = moment().format('YYYY-MM-DD h:m:s')
    const [loadPublish, setLoadPublish] = useState(false)
    const [date, setDate] = useState([
        new Date(),
        new Date(dateNow.setDate(dateNow.getDate() + 7))
    ])
  
    const handleSubmit = () => {
        setAddLoad(true)
        const param = new FormData()

        param.append('image', image)
        param.append('allocation', alloc)
        param.append('active', active)
        param.append("date", JSON.stringify(date))
        param.append('desc', desc)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/event.php/?index=16`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    getRaffle()
                    setAddLoad(false)
                    Toast.fire({
                        icon: 'success',
                        text: 'Success adding raffle',
                        position: 'top-right'
                    })
                } else {
                    setAddLoad(false)
                    Toast.fire({
                        icon: 'error',
                        text: 'Failed adding raffle',
                        position: 'top-right'
                    })
                }
            })
    }

    const handleChangeAlloc = (val) => {
        setAlloc(val)
    }

    const handleChangeActive = (val) => {
        setActive(val)
    }

    const getRaffle = async () => {
        axios({
            method: "GET",
            url: `${global.SRV}api_hoops_cms/controler/event.php/?index=6`,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                if (res.data.state === 200) {
                    setDataRaffle(res.data.data)
                }
            })
    }

    const getRaffleProduct = async (idRaffle) => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_hoops_cms/controler/event.php/?index=7&id_raffle=${idRaffle}`,
        })
            .then(res => {
                if (res.data.state === 200) {
                    setProductRaffle(res.data.data)
                }
            })
    }

    const handleImageChange = (e) => {
        let file = e.target.files
        setImage(file[0])
    }

    const handleShowEdit = (data) => {
        setSelId(data.id_raffle)
        setImage(image)
        setDesc(data.raffle_desc)
        setAlloc(data.special_for)
        setActive(data.is_active)
        setDate([
            new Date(data.begin_time),
            new Date(data.end_time)
        ])

        setShowEdit(true)
    }

    const handleShowInfo = (data) => {
        getRaffleProduct(data.id_raffle)
        getRaffleParticipant(data.id_raffle)
        setSelRaffleData(data)
        setShowModalInfo(true)
    }

    const handleUpdate = () => {
        setEditLoad(true)
        const param = new FormData()

        param.append('image', image)
        param.append('allocation', alloc)
        param.append('active', active)
        param.append("date", JSON.stringify(date))
        param.append('desc', desc)
        param.append('id_raffle', selId)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/event.php/?index=17`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    setShowEdit(false)
                    getRaffle()
                    setEditLoad(false)
                    Toast.fire({
                        icon: 'success',
                        text: 'Success update raffle',
                        position: 'top-right',
                    })
                } else {
                    setEditLoad(false)
                    Toast.fire({
                        icon: 'error',
                        text: 'Failed update raffle',
                        position: 'top-right',
                    })
                }
            })
    }

    const getRaffleParticipant = (idRaffle) => {
        axios({
            method: "GET",
            url: `${global.SRV}api_hoops_cms/controler/event.php/?index=9&id_raffle=${idRaffle}`,
        })
            .then(res => {
                if (res.data.state) {
                    setRafflePrt(res.data.data)
                }
            })
    }

    const handleDelete = (id) => {
        const msg = window.confirm("Sure you want to delete this raffle?")

        if (msg) {
            setDelLoad({
                status: true,
                id: id
            })
            const param = new URLSearchParams()

            param.append('id_raffle', id)

            axios({
                method: "POST",
                url: `${global.SRV}api_hoops_cms/controler/event.php/?index=18`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: param
            })
                .then(res => {
                    if (res.data.state === 200) {
                        setDelLoad({
                            status: true,
                            id: id
                        })
                        getRaffle()
                        Toast.fire({
                            icon: 'success',
                            text: 'Success remove selected raffle',
                            position: 'top-right',
                        })
                    } else {
                        setDelLoad({
                            status: true,
                            id: id
                        })
                        Toast.fire({
                            icon: 'error',
                            text: 'Failed remove selected raffle',
                            position: 'top-right',
                        })
                    }
                })
        }
    }

    const updateState = (id) => {
        const param = new URLSearchParams()
        param.append('id_raffle', id)
    }

    const handleChangeEnable = (id) => {
        const param = new URLSearchParams()
        param.append('id_raffle', id)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/event.php/?index=19`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    Toast.fire({
                        icon: 'success',
                        text: 'Success update raffle status',
                        position: 'top-right'
                    })
                } else {
                    Toast.fire({
                        icon: 'error',
                        text: 'Failed update raffle status',
                        position: 'top-right'
                    })
                }
            })
    }

    const handlePublish = (data) => {
        const msg = window.confirm("Do you want to publish the winner now?")
        const param = data
        
        if (msg){
            setLoadPublish(true)
            axios({
                method: "POST",
                url: `${global.SRV}api_hoops_cms/controler/event.php/?index=22`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: param
            })
            .then (res => {
                setLoadPublish(false)
                if (res.data.state === 200) {
                    getRaffle()
                    setShowModalInfo(false)
                } else {
                    Toast.fire({
                        icon:"error",
                        text:"Failed to publish winner..",
                        position:"center"
                    })
                }
            })
        }
    }

    useEffect(() => {
        getRaffle()
    }, [])
    
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Raffle</div>
                <div className="text-muted">This list of created Raffle</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center">
                <Col xl={6} lg={6} md={8} sm={12} xs={12} className="mb-5">
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Input
                            label="Image"
                            type="file"
                            icon="image"
                            className="mb-3"
                            required
                            onChange={(e) => handleImageChange(e)}
                        />
                        <Form.Group>
                            <Form.Input type="text" label="Raffle Description" onChange={(e) => setDesc(e.target.value)} required />
                            <Form.Field required>
                                <label>Registration Date</label>
                                <DateRangePicker
                                    defaultValue={date}
                                    format="yyyy-MM-dd HH:mm:ss"
                                    appearance="default"
                                    size="lg"
                                    style={{ width: "100%" }}
                                    placement="autoVertical"
                                    onOk={setDate}
                                    required
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group className="mb-3" grouped>
                            <Row>
                                <Col xl={6}>
                                    <label className="fw-bold">Allocation</label>
                                    <Form.Radio
                                        label="All Customer"
                                        value='A'
                                        checked={alloc === 'A'}
                                        onChange={(e, { value }) => handleChangeAlloc(value)}
                                    />
                                    <Form.Radio
                                        label="Customer"
                                        value='C'
                                        checked={alloc === 'C'}
                                        onChange={(e, { value }) => handleChangeAlloc(value)}
                                    />
                                    <Form.Radio
                                        label="Member"
                                        value='M'
                                        checked={alloc === 'M'}
                                        onChange={(e, { value }) => handleChangeAlloc(value)}
                                    />

                                </Col>
                                <Col xl={6}>
                                    <label className="fw-bold">Activate Now</label>
                                    <Form.Radio
                                        label="Yes"
                                        value='Y'
                                        checked={active === 'Y'}
                                        onChange={(e, { value }) => handleChangeActive(value)}
                                    />
                                    <Form.Radio
                                        label="No"
                                        value='N'
                                        checked={active === 'N'}
                                        onChange={(e, { value }) => handleChangeActive(value)}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Button content="Add New Raffle" icon="plus" color="facebook" type="submit" loading={addLoad} disabled={addLoad} />
                    </Form>

                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h3 className="mb-2">Created Raffle</h3>
                    <table className="table">
                        <thead className="bg-light">
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>Raffle</th>
                                <th>Begin</th>
                                <th>End</th>
                                <th>Allocation</th>
                                <th>Active</th>
                                <th>Winner Published</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataRaffle?.map((item, i) =>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td><Image src={item.img} height={30} width={60} rounded /></td>
                                        <td>{item.raffle_desc}</td>
                                        <td>{item.begin_time}</td>
                                        <td>{item.end_time}</td>
                                        <td>{
                                            item.special_for === 'A' ? 'All Customer' : item.special_for === 'C' ? 'Customer' : 'Member'
                                        }</td>
                                        <td>
                                            {
                                                <Checkbox
                                                    toggle
                                                    defaultChecked={item.is_active === 'Y' ? true : false}
                                                    style={{ transform: 'scale(.7)' }}
                                                    onChange={(e) => handleChangeEnable(item.id_raffle)}
                                                />
                                            }
                                        </td>
                                        <td>{item.is_published === 'N' ? 'Not Yet' : <><Icon name="check circle outline" color="blue"/><span>Published</span></>}</td>
                                        <td>
                                            <Button.Group size="tiny" basic>
                                                <Popup
                                                    content="Product"
                                                    size="small"
                                                    trigger={
                                                        <Button
                                                            icon="box"
                                                            onClick={() => navigate('raffle_detail', { state: item })}
                                                        />
                                                    }
                                                />
                                                <Popup
                                                    content="Edit selected raffle"
                                                    size="small"
                                                    trigger={
                                                        <Button
                                                            icon="edit"
                                                            onClick={() => handleShowEdit(item)}
                                                        />
                                                    }
                                                />
                                                <Popup
                                                    size="mini"
                                                    content="Raffle Information"
                                                    trigger={
                                                        <Button
                                                            icon="info circle"
                                                            onClick={() => handleShowInfo(item)}
                                                        />
                                                    }
                                                />
                                                <Popup
                                                    content="Remove this raffle"
                                                    size="small"
                                                    trigger={
                                                        <Button
                                                            icon="trash"
                                                            onClick={() => handleDelete(item.id_raffle)}
                                                            loading={delLoad.id === item.id_raffle && delLoad.status}
                                                            disabled={delLoad.id === item.id_raffle && delLoad.status}
                                                        />
                                                    }
                                                />
                                            </Button.Group>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                </Col>
            </Row>

            <Modal backdrop='static' keyboard={false} open={showEdit} onClose={() => setShowEdit(false)}>
                <Modal.Header>
                    <Modal.Title className="fs-5">Edit Raffle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form widths="equal">
                        <Form.Input
                            label="Image"
                            type="file"
                            icon="image"
                            className="mb-3"
                            onChange={(e) => handleImageChange(e)}
                        />
                        <Form.Input type="text" label="Raffle Description" value={desc} onChange={(e) => setDesc(e.target.value)} required />
                        <Form.Field required>
                            <label>Registration Date</label>
                            <DateRangePicker
                                defaultValue={date}
                                format="yyyy-MM-dd HH:mm:ss"
                                appearance="default"
                                size="lg"
                                style={{ width: "100%" }}
                                placement="autoVertical"
                                onOk={setDate}
                                required
                            />
                        </Form.Field>
                        <Form.Group className="mb-3" grouped>
                            <label >Allocation</label>
                            <Form.Radio
                                label="All Customer"
                                value='A'
                                checked={alloc === 'A'}
                                onChange={(e, { value }) => handleChangeAlloc(value)}
                            />
                            <Form.Radio
                                label="Customer"
                                value='C'
                                checked={alloc === 'C'}
                                onChange={(e, { value }) => handleChangeAlloc(value)}
                            />
                            <Form.Radio
                                label="Member"
                                value='M'
                                checked={alloc === 'M'}
                                onChange={(e, { value }) => handleChangeAlloc(value)}
                            />
                        </Form.Group>
                        <Form.Field className="text-end mt-4">
                            <Button type="submit" content="Update Raffle" icon="edit" color="facebook" onClick={handleUpdate} loading={editLoad} disabled={editLoad} />
                        </Form.Field>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal backdrop='static' keyboard={false} open={showModalInfo} onClose={() => setShowModalInfo(false)}>
                <Modal.Header>
                    <Modal.Title className="fs-5">Raffle Information</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '0.9em' }}>
                    <table className="table">
                        <tbody>
                            <tr>
                                <th>Raffle Description</th>
                                <td>{selRaffleData?.raffle_desc}</td>
                            </tr>
                            <tr>
                                <th>Start Registration</th>
                                <td>{moment(selRaffleData?.begin_time).tz('Asia/Jakarta').format('LLLL')}</td>
                            </tr>
                            <tr>
                                <th>Closing Registration</th>
                                <td>{moment(selRaffleData?.end_time).tz('Asia/Jakarta').format('LLLL')}</td>
                            </tr>
                            <tr>
                                <th>Special For</th>
                                <td>{selRaffleData?.special_for === 'A' ? 'All Customer' : selRaffleData?.special_for === 'C' ? 'Customer' : 'Member'}</td>
                            </tr>
                            <tr>
                                <th>Total Participant</th>
                                <td>{rafflePrt?.length}</td>
                            </tr>
                            <tr>
                                <th className="align-middle">Raffle Status</th>
                                <td className="align-middle">
                                    {
                                        (() => {
                                            if (selRaffleData?.end_time >= timeNow){
                                                return <><Icon name="certificate" loading color="green"/><span>Registration is in progress</span></>
                                            } else {
                                                if (selRaffleData?.is_published === 'Y'){
                                                    return <div><Icon name="check circle outline" color="blue"/> Winner Published</div>
                                                } else {
                                                    return (<>
                                                        <Icon name="certificate" color="grey"/>
                                                        <span className="text-muted me-4">Registration ends</span>
                                                        <span ><Button loading={loadPublish} disabled={loadPublish} icon="paper plane outline" content="Publish Winner" size="tiny" basic color="blue" onClick={() => handlePublish(selRaffleData)}/></span>
                                                    </>)
                                                }
                                            }
                                        })()
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div className="fw-bold mb-2">Raffle Products</div>
                                    <Row>
                                        {
                                            productRaffle?.map((item, i) => (
                                                <Col md={6} lg={6} xl={6}>
                                                    <Stack direction="horizontal" gap={2}>
                                                        <Image src={item.img} width={60} height={55} rounded className="border" />
                                                        <Stack direction="vertical" gap={0} >
                                                            <div className="text-muted fw-bold" style={{ fontSize: '0.8em' }}>{item.id_product}</div>
                                                            <div className="text-truncated" style={{fontSize:'0.8em'}}>{item.name}</div>
                                                            <div className="fw-bold">Participant : {rafflePrt?.filter(p => p.id_product === item.id_product).length}</div>                                                                
                                                        </Stack>
                                                    </Stack>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </Container>
    )
}