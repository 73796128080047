import axios from "axios";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Stack, Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Divider, Form, Button, Input, Select } from "semantic-ui-react";

const ProductAttributeEdit = () => {
    const location = useLocation()
    const [atribute, setAtribute] = useState([])
    const [atributeAll, setAtributeAll] = useState([])
    const [productColor, setProductColor] = useState([])
    const [selectedColor, setSelectedColor] = useState([])
    const [attParam, setAttrParam] = useState([])
    const [weight, setWeight] = useState(0)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [length, setLength] = useState(0)
    const [idProduct, setIdProduct] = useState(null)
    const [alertShow, setAlertShow] = useState(false)
    const [idDim, setIdDim] = useState('')


    const handleSubmit = () => {
        const param = {
            id_product: idProduct,
            color: atribute,
            id_dimension: idDim,
            weight: weight,
            height: height,
            width: width,
            length: length,
        }
        console.log(param)
        axios.post("https://hoopsdevteam.com/api_web/controler/cms.php/?index=24", param)
            .then(res => {
                if (res.data.state === 200) {
                    setAlertShow(true)
                }
            })
    }

    const handleChangeColor = (value) => {
        setSelectedColor(value)
    }

    useEffect(() => {
        let temp = selectedColor.map(i => ({ id_product: idProduct, id_color: i }))
        setAtribute(temp)
    }, [selectedColor])

    useEffect(() => {
        setIdProduct(location.state.selected.id_product)

        const getColorById = async (id) => {
            await axios.post("https://hoopsdevteam.com/api_web/controler/cms.php/?index=21", { id_product: id })
                .then(res => {
                    let arr = []

                    if (res.data.state === 200) {
                        res.data.data.map(o => arr.push(o.id_color))
                        setSelectedColor(arr)
                    }
                })
        }

        const getColor = async () => {
            const endpoint = "https://hoopsdevteam.com/api_web/controler/cms.php/?index=7"

            await axios.get(endpoint)
                .then(res => {
                    if (res.data.state === 200) {
                        let data = res.data.data
                        let ar = []

                        data.map((item, idx) => {
                            let colorGroup = <Stack direction="horizontal" gap={0} style={{ display: 'inline' }}>
                                <span className="me-4">
                                    <canvas className="border-start border-dark border-top border-bottom" style={{ backgroundColor: item.color_main }} height="15%" width="15%" />
                                    <canvas className="border-dark border-top border-bottom" style={{ backgroundColor: item.color_secondary }} height="15%" width="15%" />
                                    <canvas className="border-end border-dark border-top border-bottom" style={{ backgroundColor: item.color_accent }} height="15%" width="15%" />
                                </span>
                                <span>{item.name}</span>
                            </Stack>

                            ar.push(
                                {
                                    key: item.id_color,
                                    value: item.id_color,
                                    text: colorGroup,
                                }
                            )
                        })
                        setProductColor(ar)
                    }
                })
        }

        const getDimension = async (id) => {
            await axios.post("https://hoopsdevteam.com/api_web/controler/cms.php/?index=20", { id_product: id })
                .then(res => {
                    if (res.data.state === 200) {
                        let data = res.data.data
                        setIdDim(data[0].id_dimension)
                        setWeight(data[0].weight)
                        setHeight(data[0].height)
                        setWidth(data[0].width)
                        setLength(data[0].length)
                    }
                })
        }



        getColor()
        getDimension(location.state.selected.id_product)
        getColorById(location.state.selected.id_product)

    }, [location.state])

    return (
        <Container fluid>
            <Row className="mt-1">
                <div className="text-facebook h3 mb-0">Edit Product Attribute</div>
                <small>
                    <span className="text-muted fw-bold">{location.state.selected.id_product} - </span>
                    <span className="fw-bold">{location.state.selected.product_name}</span>
                </small>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center">
                <Col xl={6} lg={12} md={12} sm={12}>
                    <Alert variant="success" onClose={() => setAlertShow(false)} show={alertShow} dismissible>
                        <p>Sucess, update product atribute for product : {idProduct}</p>
                    </Alert>
                </Col>
            </Row>
            <Row className="mb-3 mt-3 d-flex justify-content-center">
                <Col xl={6} lg={12} md={12} sm={12}>
                    <Form widths="equal" onSubmit={() => handleSubmit()}>
                        <Form.Dropdown
                            control={Select}
                            options={productColor}
                            label={{ children: "Color" }}
                            multiple
                            value={selectedColor}
                            onChange={(e, { value }) => handleChangeColor(value)}
                        />
                        <Form.Field>
                            <label>Weight</label>
                            <Input
                                value={weight}
                                step="0.1"
                                label={{ basic: true, content: 'kg' }}
                                labelPosition="right"
                                type="number"
                                onChange={(e) => setWeight(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Width</label>
                            <Input
                                value={width}
                                label={{ basic: true, content: 'cm' }}
                                labelPosition="right"
                                type="number"
                                onChange={(e) => setWidth(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Height</label>
                            <Input
                                value={height}
                                label={{ basic: true, content: 'cm' }}
                                labelPosition="right"
                                type="number"
                                onChange={(e) => setHeight(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Length</label>
                            <Input
                                value={length}
                                label={{ basic: true, content: 'cm' }}
                                labelPosition="right"
                                type="number"
                                onChange={(e) => setLength(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Button content="Update Attribute" className="mt-5" color="facebook" type="submit" />
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ProductAttributeEdit