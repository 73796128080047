import React, {useState, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap"
import {Form,Radio, Divider, Image} from "semantic-ui-react"
import axios from "axios";

const CategoryEdit = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const [displayed, setDisplayed] = useState('')
    const [image, setImage] = useState(null)
    const [thumbnail, setThumbnail] = useState(null)
    const [categoryName, setCategoryName] = useState('')
    const [position, setPosition] = useState(0)

    const handleInputChangeImage = (e) => {
        let image = e.target.files
        setImage(image[0])
    }
    const handleInputChangeThumbnail = (e) => {
        let image = e.target.files
        setThumbnail(image[0])
    }

    const handleChangeDisplayed = (value) => (setDisplayed(value))

    const handleSubmit = (e) => {
        const data = new FormData()
        const endpoint = "https://hoopsdevteam.com/api_web/controler/cms.php/?index=22"
  
        data.append('category_name', categoryName)
        data.append('position', position)
        data.append('display', displayed)
        data.append("image", image)
        data.append("thumbnail", thumbnail)
        data.append("id_category", location.state.ID)

        axios({
            method:"POST",
            url:endpoint,
            headers:{'Content-Type': 'multipart/form-data'},
            data:data
        })
            .then(res => {
                console.log(res.data)
                if (res.data.state === 200){
                    navigate('/category')
                }
            })
    }

    useEffect(() => {
        setCategoryName(location.state.category_name)
        setPosition(location.state.position)
        setImage(location.state.header_image)
        setThumbnail(location.state.thumbnail_image)
        setDisplayed((location.state.on_page == 0 && location.state.on_navigation == 0 ? '' : (location.state.on_page == 1 && location.state.on_navigation == 0 ? 'page' : 'navigation')))
    }, [location.state])
    
    return(
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Edit Category</div>
                <div className="text-muted">Edit selected category, which will be displayed on the website</div>
            </Row>
            <Divider />
            <Row className="mb-3 d-flex justify-content-center">
                <Col sm={12} md={12} lg={8} xl={6}>
                    <Form widths="equal" onSubmit={(e) => handleSubmit(e)} >
                        <Form.Input label="Category Name" value={categoryName} onChange={(e, { value }) => setCategoryName(value)} required/>
                        <Form.Input label="Position" type="number" value={position} onChange={(e, { value }) => setPosition(value)} />
                        <Image src={image} style={{height:100}}/>
                        <Form.Input
                            label="Images"
                            type="file"
                            icon="image"
                            name="image"
                            className="mb-0"
                            onChange={(e) => handleInputChangeImage(e)}
                        />
                        <div className="mb-2"><small className="text-muted">Images must be in *.png format and file size no more than 500kb (1920x300)</small></div>
                        <Image src={thumbnail} style={{height:100}}/>
                        <Form.Input
                            label="Images Thumbnail"
                            type="file"
                            icon="image"
                            name="thumbnail"
                            className="mb-0"
                            onChange={(e) => handleInputChangeThumbnail(e)}
                        />
                        <small className="text-muted">Images must be in *.png format and file size no more than 250kb (150x150)</small>

                        <Form.Group inline className="mt-4">
                            <label>Displayed</label>
                            <Form.Field
                                control={Radio}
                                label="On navigation"
                                value="navigation"
                                checked={displayed === "navigation"}
                                onChange={(e, { value }) => handleChangeDisplayed(value)}
                            />
                            <Form.Field
                                control={Radio}
                                label="On Page"
                                value="page"
                                checked={displayed === "page"}
                                onChange={(e, { value }) => handleChangeDisplayed(value)}
                            />
                        </Form.Group>
                        <Form.Button content="Save Category" className="mt-5" color="facebook" />
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default CategoryEdit