import React, { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Dimmer, Divider, Form, Loader } from "semantic-ui-react";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { DateRangePicker } from "rsuite";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Modal from "rsuite/Modal"
import Table from "../component/Table";

const VoucherSpecialAdd = () => {
    const navigate = useNavigate()
    const [customerOpt, setCustomerOpt] = useState([])
    const [idCustomer, setIdCustomer] = useState('')
    const [voucherName, setVoucherName] = useState('')
    const [voucherDesc, setVoucherDesc] = useState('')
    const [voucherCode, setVoucherCode] = useState('')
    const [discountType, setDiscountType] = useState('')
    const [discountValue, setDiscountValue] = useState(0)
    const [discountPercent, setDiscountPercent] = useState(0)
    const [discountMax, setDiscountMax] = useState(0)
    const [minTrx, setMinTrx] = useState(0)
    const [expose, setExpose] = useState('N')
    const [combine, setCombine] = useState('N')
    const [custLoader, setCustLoader] = useState(false)
    const [modalCustomer, setModalCustomer] = useState(false)
    const [customerData, setCustomerData] = useState([])

    const columnCustomer = useMemo(() => [
        {
            Header:'ID',
            accessor:'id_customer',
        },
        {
            Header:'Name',
            accessor:'first_name',
            Cell:({cell}) => (cell.value + ' ' + cell.row.original.last_name)
        },
        {
            Header:'Email',
            accessor:'email',
        },
        {
            Header:'Member',
            accessor:'isMember',
        },
        {
            Header:'Action',
            accessor:null,
            Cell:({cell}) => (
                <Button.Group basic size="mini">
                    <Button icon="arrow alternate circle right outline" onClick={() => handleClickCustomer(cell.row.original)}/>
                </Button.Group>
            )
        }
    ])

    const handleClickCustomer = (data) => {
        setIdCustomer(data.id_customer)
        setModalCustomer(false)
    }

    const handleCheckExpose = (e) => {
        if (e.checked) {
            setExpose('Y')
        } else {
            setExpose('N')
        }
    }

    const handleCheckCombine = (e) => {
        if (e.checked) {
            setCombine('Y')
        } else {
            setCombine('N')
        }
    }

    const [date, setDate] = useState([
        new Date(moment()),
        new Date(moment().add(7, 'days'))
    ])

    const type = [
        { key: 'P', text: 'Percent', value: 'P' },
        { key: 'N', text: 'Nominal', value: 'N' },
        { key: 'O', text: 'Ongkir', value: 'O' },
    ]

    const getCustomer = async () => {
        setCustLoader(true)
        await axios.get(`${global.SRV}api_hoops_cms/controler/customer.php/?index=1`)
            .then(res => {
                if (res.data.state === 200) { console.log(res.data.data)
                    setCustomerData(res.data.data.filter(i => i.verified === '1' && i.isActive === 'Y'))
                    setCustomerOpt(res.data.data.map(o => ({
                        key: o.id_customer,
                        text: o.email,
                        value: o.id_customer
                    })))
                }
                setCustLoader(false)
            })
    }

    const handleSubmit = () => {
        const param = {
            id_customer: idCustomer,
            voucher_name: voucherName,
            voucher_desc: voucherDesc,
            voucher_code: voucherCode,
            dsc_type: discountType,
            dsc_value: discountValue,
            dsc_max: discountMax,
            min_trx: minTrx,
            expose: expose,
            combine: combine,
            date_range: date
        }

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/voucher.php/?index=5`,
            headers: { 'Content-Type': 'text/plain' },
            data: param
        })
            .then(res => {
                console.log(res.data)
                if (res.data.state === 200) {
                    navigate('/voucher_special')
                }
            })
    }

    useEffect(() => {
        getCustomer()
    }, [])

    return (
        <>
        <Container fluid>
            <Dimmer active={custLoader}>
                <Loader>Loading Customer Email</Loader>
            </Dimmer>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0"> Add Special Voucher</div>
                <div className="text-muted">Adding new special voucher</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={8} md={12} sm={12}>
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Field>
                            {/* <Form.Select
                                clearable
                                search
                                loading={custLoader}
                                label="Customer by Email"
                                options={customerOpt ? customerOpt : []}
                                onChange={(e, { value }) => setIdCustomer(value)}
                            /> */}
                            <Form.Input label="ID Customer" value={idCustomer} onChange={(e) => setIdCustomer(e.target.value)} readOnly action={{icon:'search', onClick:() => setModalCustomer(true)}}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Voucher Name" defaultValue={voucherName} onChange={(e, { value }) => setVoucherName(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Description" defaultValue={voucherDesc} onChange={(e, { value }) => setVoucherDesc(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Voucher Code" defaultValue={voucherCode} onChange={(e, { value }) => setVoucherCode(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Select
                                label="Discount Type"
                                options={type}
                                onChange={(e, { value }) => setDiscountType(value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Discount Fixed" type="number" defaultValue={discountValue} onChange={(e, { value }) => setDiscountValue(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Discount Percent" type="number" defaultValue={discountPercent} onChange={(e, { value }) => setDiscountPercent(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Discount Max" type="number" defaultValue={discountMax} onChange={(e, { value }) => setDiscountMax(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Minimum Transaction" type="number" defaultValue={minTrx} onChange={(e, { value }) => setMinTrx(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Checkbox label="Expose" defaultChecked={false} onChange={(e, data) => handleCheckExpose(data)} />
                            <Form.Checkbox label="Combinable" defaultChecked={false} onChange={(e, data) => handleCheckCombine(data)} />
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <label>Active Date</label>
                            <DateRangePicker
                                defaultValue={date}
                                format="yyyy-MM-dd HH:mm:ss"
                                appearance="default"
                                size="lg"
                                style={{ width: "100%" }}
                                placement="topStart"
                                onOk={setDate}
                            />
                        </Form.Field>
                        <Form.Field className="mb-3 mt-5">
                            <Form.Button content="Save Voucher" color="facebook" />
                        </Form.Field>
                    </Form>
                </Col>
            </Row>
        </Container>

        <Modal open={modalCustomer} onClose={() => setModalCustomer(false)} backdrop="static" overflow={false} size="lg">
            <Modal.Header>
                <Modal.Title className="h4">Customer Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table data={customerData} columns={columnCustomer}/>
            </Modal.Body>
        </Modal>

        </>
    )
}

export default VoucherSpecialAdd