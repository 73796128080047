import axios from "axios";
import React from "react";
import { useState } from "react";
import { Col, Container, Row, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Radio } from "semantic-ui-react";

const CategoryAdd = () => {
    const [displayed, setDisplayed] = useState('navigation')
    const [image, setImage] = useState(null)
    const [thumbnail, setThumbnail] = useState(null)
    const [categoryName, setCategoryName] = useState('')
    const [position, setPosition] = useState(0)
    const [alertShow, setAlertShow] = useState(false)
    const navigate = useNavigate()

    const handleInputChangeImage = (e) => {
        let image = e.target.files
        setImage(image[0])
    }
    const handleInputChangeThumbnail = (e) => {
        let image = e.target.files
        setThumbnail(image[0])
    }

    const handleChangeDisplayed = (value) => (setDisplayed(value))

    const handleSubmit = (e) => {
        const data = new FormData()
        const endpoint = "https://hoopsdevteam.com/api_web/controler/cms.php/?index=11"

        data.append('category_name', categoryName)
        data.append('position', position)
        data.append('display', displayed)
        data.append("image", image)
        data.append("thumbnail", thumbnail)
        axios.post(endpoint, data)
            .then(res => {
                if (res.data.state === 200) {
                    setAlertShow(true)
                    navigate('/category')
                    resetFile()
                }
            })
    }

    const resetFile = () => {
        document.getElementsByTagName("input")[0].value = null;
    }

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Add New Category</div>
                <div className="text-muted">You can add a category here, which will be displayed on the website</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center">
                <Col sm={12} md={12} lg={8} xl={6}>
                    <Alert variant="success" onClose={() => setAlertShow(false)} show={alertShow} dismissible>
                        <p>
                            Success, adding new category product
                        </p>
                    </Alert>
                </Col>
            </Row>
            <Row className="mb-3 d-flex justify-content-center">
                <Col sm={12} md={12} lg={8} xl={6}>
                    <Form widths="equal" onSubmit={(e) => handleSubmit(e)}>
                        <Form.Input label="Category Name" value={categoryName} onChange={(e, { value }) => setCategoryName(value)} required />
                        <Form.Input label="Position" type="number" value={position} onChange={(e, { value }) => setPosition(value)} />
                        <Form.Input
                            label="Images"
                            type="file"
                            icon="image"
                            className="mb-0"
                            required
                            onChange={(e) => handleInputChangeImage(e)}
                        />
                        <small className="text-muted">Images must be in *.png format and file size no more than 500kb</small>

                        <Form.Input
                            label="Images Thumbnail"
                            type="file"
                            icon="image"
                            className="mb-0 mt-3"
                            onChange={(e) => handleInputChangeThumbnail(e)}
                        />
                        <small className="text-muted">Images must be in *.png format and file size no more than 250kb</small>

                        <Form.Group inline className="mt-4">
                            <label>Displayed</label>
                            <Form.Field
                                control={Radio}
                                label="On navigation"
                                value="navigation"
                                checked={displayed === "navigation"}
                                onChange={(e, { value }) => handleChangeDisplayed(value)}
                            />
                            <Form.Field
                                control={Radio}
                                label="On Page"
                                value="page"
                                checked={displayed === "page"}
                                onChange={(e, { value }) => handleChangeDisplayed(value)}
                            />
                        </Form.Group>
                        
                        <Form.Button content="Save Category" className="mt-5" color="facebook" />
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default CategoryAdd