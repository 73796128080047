import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Modal, Row, Spinner} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Button, Popup, Form, Loader, Dimmer } from "semantic-ui-react";
import Table from "../component/Table";
import axios from "axios";
import { Toast } from "../component/Toast";

export const VoucherProduct = () => {
    const navigate = useNavigate()
    const location = useLocation().state
    const [data, setData] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const [productWeb, setProductWeb] = useState([]) 
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [loadProduct, setLoadProduct] = useState(false)

    const columns = useMemo(() => [
        {
            Header : "ID_Product",
            accessor : "id_product"
        },
        {
            Header : "Product",
            accessor : "product_name"
        },
        {
            Header : "Qty",
            accessor : "qty"
        },
        {
            Header : "Base Price",
            accessor : "price",
            Cell : (cell) => ('Rp ' + cell.value)
        },
        {
            Header : "Action",
            accessor : null,
            Cell : ({cell}) => {
                return (
                    <Popup 
                        size="mini"
                        content="Remove this product" 
                        trigger={<Button icon="eraser" basic size="mini" onClick={() => removeProduct(cell.row.original.id_product, location.id_voucher)}/>}
                    />
                )
            } 
        },
    ])

    const removeProduct = (id_product, id_voucher) => {
        const param = {
            id_product : id_product,
            id_voucher : id_voucher
        }

        axios ({
            method:"POST",
            url:`${global.SRV}api_hoops_cms/controler/voucher.php/?index=11`,
            headers:{'Content-Type': 'application/json'},
            data:param
        })
        .then ( res => {
            if (res.data.state === 200){
                Toast.fire({
                    icon:'success',
                    text : 'Success remove product from this voucher',
                    position:'top-end'
                })
                getProductVoucher(id_voucher)
            }
        })
    }

    const getProductWeb = async () => {
        setLoadProduct(true)
        await axios.get("https://hoopsdevteam.com/api_web/controler/cms.php?index=2")
        .then (res => {
            if (res.data.state === 200){
                setProductWeb(() => {
                        let opt = []
                       
                        res.data.data.map(i => {
                            opt.push({
                                key : i.id_product,
                                value:i.id_product,
                                text: i.id_product,
                            })
                        } )

                        return opt.filter(o => !data.some(x => o.value == x.id_product))
                    }
                )
                setLoadProduct(false)
            }
        })
    }

    const handleSubmit = () => {
        const param = {
            id_product : selectedProduct,
            id_voucher : location.id_voucher
        }
        axios ({
            method:"POST",
            url:`${global.SRV}api_hoops_cms/controler/voucher.php/?index=10`,
            headers:{'Content-Type': 'application/json'},
            data:param
        })
        .then (res => {
            if (res.data.state === 200){
                Toast.fire({
                    icon:'success',
                    text:'Success adding product to voucher',
                    position:'top-end'
                })
                setModalAdd(false)
                getProductVoucher(location.id_voucher)
            } else {
                Toast.fire({
                    icon:'error',
                    text:'Failed adding product to voucher',
                    position:'top-end'
                })
            }
        })
    }

    const getProductVoucher = async (id_voucher) => {
        await axios({
            method:"GET",
            url:`${global.SRV}api_hoops_cms/controler/voucher.php/?index=9&id=${id_voucher}`
        })
        .then(res => {
            if (res.data.state === 200){
                setData(res.data.data)
            }
        })
    }

    const showModalAdd = () => {
        setModalAdd(true)
        getProductWeb()
    }


    useEffect(() => {
        let id_voucher = location.id_voucher
        getProductVoucher(id_voucher)
    },[location.id_voucher])
    
    return (
        <Container fluid>
            <Modal show={modalAdd} onHide={() => setModalAdd(false)} autoFocus backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Adding Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                        <Dimmer active={loadProduct} inverted>
                            <Loader inverted content='Loading Product' size="tiny"/>
                        </Dimmer>
                            <Form widths="equal" onSubmit={handleSubmit}>
                                <Form.Select 
                                    label="Select Article"
                                    search
                                    options={productWeb}
                                    onChange={(e,{value}) => setSelectedProduct(value)}
                                    required
                                />
                                <div className="text-end">
                                    <Button content="Add" icon="add" color="facebook"/>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Voucher Products</div>
                <div className="text-muted">This is a list of products added to the voucher <span style={{color:'darkblue', fontWeight:'bold'}}>{location.name}</span></div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={12} lg={12} md={12} sm={12} className="d-flex justify-content-start mb-3">
                    <Button content="Add Product" icon="add" color="facebook" onClick={showModalAdd}/>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <Table data={data} columns={columns} pageRow={10}/>
                </Col>
            </Row>
        </Container>
    )
}