import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Col, Container, Row, Stack, Image, Modal } from "react-bootstrap";
import { Button, Divider, Icon, Input, Label, List, Popup } from "semantic-ui-react";
import Table from "../component/Table";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import AGtable from "../component/AGTable";

const Order = () => {
    const [order, setOrder] = useState([])
    const navigate = useNavigate()
    const [orderDetail, setOrderDetail] = useState([])
    const [totalOrder, setTotalOrder] = useState(0)
    const [loadingHide, setLoadingHide] = useState(true)
    // const [searchValue, setSearchValue] = useState('')
    const [loading, setLoading] = useState(true)
    const [setlementOrder, setSetlementOrder] = useState(0)
    const [inDelivery, setInDelivery] = useState(0)
    const [onBooking, setOnBooking] = useState([])
    const [modalBooking, setModalBooking] = useState(false)
    const [exportLoader, setExportLoader] = useState(false)
    const [dateRange, setDateRange] = useState(() => {
        if (window.sessionStorage.getItem('startOrd')) {
            return [
                new Date(JSON.parse(window.sessionStorage.getItem('startOrd'))),
                new Date(JSON.parse(window.sessionStorage.getItem('endOrd')))
            ]
        } else {
            return [
                new Date(moment().startOf('month')),
                new Date(moment()),
            ]
        }
    })

    const updateDownloadState = (idOrder) => {
        const param = { id_order: idOrder }

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/order.php/?index=7`,
            headers: { 'Content-Type': 'application/json' },
            data: param
        }).then(res => {
            if (res.data.state === 200) {
                getOrder(dateRange)
            }
        })
    }

    const createFileTxt = (header) => {
        let data;  // Declare the variable

        // Call getDetail and handle the response using .then()
        getDetailForTxt(header.id_order, header.id_customer)
            .then((result) => {
                data = result;
                console.log(data)


                if (data.customer != null && data.order_detail) {
                    const customer = data.customer;
                    const orderDetail = data.order_detail;

                    let jml = 0;
                    let total_dsc = 0;
                    let total_harga_ahir = 0;
                    let qty = 0;

                    orderDetail.forEach((val) => {
                        jml += parseFloat(val.price);
                        total_dsc += parseFloat(val.discount_total)
                        total_harga_ahir += jml - total_dsc
                        qty += parseInt(val.qty)
                    });

                    let text = `"ShowRoom ONLINE-01"\r\n"Date ${moment(
                        customer.order_date
                    ).format("YYYY-MM-DD")}"\r\n"TOP OF TABLE   POS_INVOICE_HEADER      "\r\n"01| Z| Z| Z| JAVA| ONLINE| 01| POS|  | ${customer.id_order}| ${moment(
                        customer.order_date
                    ).format("YYYY-MM-DD HH:mm:ss")}| NA| ONLINE| 01| ${customer.id_order}| STD| 0| 0| ${jml}| ${customer.total_price
                        }| ${total_dsc}| 0| 0| 0| 0| 0| 0| 0| 0| ${customer.total_payment
                        }| ${customer.total_voucher}| 0| NA| 0| | | ${customer.total_weight}|  | 0| ${qty}|  |  | ${moment(customer.order_date).format(
                            "YYYY-MM-DD HH:mm:ss"
                        )}| ONLINE| | | 0| NA | | 0| 0|S1|0|  "\r\n"TOP OF TABLE   POS_INVOICE_DETAIL      "\r\n${orderDetail
                            .map((pr, idx) => {
                                return `"01| Z| Z| Z| JAVA| ONLINE| 01| NA| POS| ${customer.id_order}| ${pr.qty}| | | | | | ${pr.id_product}| 01| ${pr.size}| ${pr.barcode} | ONLINE| 0| | | ${pr.discount}| 0| ${pr.price * pr.qty}| ${pr.discount_total}| ${pr.total_price}| 0| N| ${customer.total_weight}| ${moment(customer.order_date).format("YYYY-MM-DD")}| ${pr.qty
                                    }| ${moment(customer.order_date).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                    )}| ONLINE| 0| NA | 100| | |"`;
                            })
                            .join(
                                "\r\n"
                            )}\r\n"TOP OF TABLE   IM_STOCK_TXN            "\r\n${orderDetail
                                .map((pr, idx) => {
                                    return `"ONLINE| 01| NA| ${pr.id_product}| 01| ${pr.size}| ${moment(
                                        customer.order_date
                                    ).format("YYYY-MM-DD HH:mm:ss")}| POS| ${customer.id_order}| 1| 0|  |  | -${pr.qty}|  |  | -${pr.qty}| -0| 0| 0|  |  | 1 | ${pr.price
                                        }| ${moment(customer.order_date).format("YYYY-MM-DD HH:mm:ss")}| | ${customer.id_order
                                        }|  |  |  |  |  |  |  |  |  |  |  | ONLINE   | 0| 0|  |  |  |  |  |  |  |  |  |  "`;
                                })
                                .join(
                                    "\r\n"
                                )}\r\n"TOP OF TABLE   POS_CASHIERTXN          "\r\n"TOP OF TABLE   POS_DISCOUNT_TXN        "\r\n"TOP OF TABLE   POS_OTHER_CASHIER_TXN   "\r\n"TOP OF TABLE   POS_CARD_DEBIT_TXN      "`;


                    const element = document.createElement("a");
                    const file = new Blob([text], {
                        type: "text/plain",
                    });
                    element.href = URL.createObjectURL(file);
                    element.download = `Sales_ONLINE_No_${customer.id_order}.txt`;
                    document.body.appendChild(element);
                    element.click();

                    createFileTxt(data);
                    updateDownloadState(customer.id_order)
                }

            })
            .catch((error) => {
                console.error('Error:', error.message);
            });

    }

    const rendererCustomer = (params) => {
        const image = params.data.image
        const data = params.data
        if (data.customer_avatar !== '') {
            return (
                <Fragment>
                    <Stack direction="horizontal" gap={2}>
                        <Image src={data.customer_avatar} width={36} height={36} roundedCircle />
                        <Stack direction="vertical" gap={0}>
                            <div className="text-capitalize" style={{ lineHeight: 'normal' }}>{data.customer}</div>
                            <div className="text-muted" style={{ fontSize: '0.9em', lineHeight: 'normal' }}>{data.email}</div>
                        </Stack>
                    </Stack>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <Stack direction="horizontal" gap={2}>
                        <Image src={`https://ui-avatars.com/api/?name=${data.first_name}+${data.last_name}`} width={36} height={36} roundedCircle />
                        <Stack direction="vertical" gap={0}>
                            <div className="text-capitalize" style={{ lineHeight: 'normal' }}>{data.customer}</div>
                            <div className="text-muted" style={{ fontSize: '0.9em', lineHeight: 'normal' }}>{data.email}</div>
                        </Stack>
                    </Stack>
                </Fragment>
            )
        }
    }

    const rendererStatus = (params) => {
        let data = params.data
        return (
            data ? <Label className="text-capitalize" content={data.order_state} size="mini" style={{ backgroundColor: data.state_color }} /> : <></>
        )
    }

    const rendererAction = (params) => {
        let data = params.data
        // if (data.id_order == 'OD23120639') { console.log(data.isDownloaded) }
        return (
            <Fragment>
                <Button.Group size="mini" basic>
                    <Popup content="View Detail" size="mini" trigger={<Button icon="search" size="mini" onClick={() =>
                        navigate(`/order_detail/`, { state: data })
                    } />} />
                    {
                        data.order_state !== 'CANCEL' ? 
                        <Popup content="Invoice" trigger={<Button icon="file alternate outline" onClick={() => navigate('/order_invoice', { state: data })} />} size="mini" />
                        : <></>
                    }
                    {data.order_state === 'IN DELIVERY' && !data.no_resi ?
                        <Popup content="Generate Resi" trigger={<Button icon="magic"
                            onClick={() => getDetail(data.id_order, data.id_customer)} />}
                            size="mini" />
                        : <></>
                    }
                    {data.order_state === 'IN DELIVERY' ?
                        <Popup content="Delivery Label" trigger={<Button icon="sticky note outline"
                            onClick={() => navigate('/order_delivery', { state: data })} />}
                            size="mini" />
                        : <></>
                    }
                    {
                        data.order_state === 'IN DELIVERY' || data.order_state === 'ARRIVE AT DESTINATION' ?
                            data.isDownloaded == '0' ?
                                <Popup content="Download Order" trigger={<Button icon="download"
                                    onClick={() => createFileTxt(data)} />}
                                    size="mini" />
                                :
                                <Popup content="Already Downloaded" trigger={<Button icon="check circle outline"
                                    onClick={() => createFileTxt(data)} />}
                                    size="mini" />
                            : <></>
                    }
                </Button.Group>
            </Fragment>

        )
    }

    const columns = useMemo(() => [
        {
            Header: "Order ID",
            accessor: "id_order"
        },
        {
            Header: "Customer",
            accessor: "customer",
            Cell: ({ cell }) => {
                if (cell.row.original.customer_avatar !== '') {
                    return (
                        <Fragment>
                            <Stack direction="horizontal" gap={2}>
                                <Image src={cell.row.original.customer_avatar} width={36} height={36} roundedCircle />
                                <Stack direction="vertical" gap={0}>
                                    <div className="text-capitalize">{cell.value}</div>
                                    <div className="text-muted" style={{ fontSize: '0.9em' }}>{cell.row.original.email}</div>
                                </Stack>
                            </Stack>
                        </Fragment>
                    )
                } else {
                    return (
                        <Fragment>
                            <Stack direction="horizontal" gap={2}>
                                <Image src={`https://ui-avatars.com/api/?name=${cell.row.original.first_name}+${cell.row.original.last_name}`} width={36} height={36} roundedCircle />
                                <Stack direction="vertical" gap={0}>
                                    <div className="text-capitalize">{cell.value}</div>
                                    <div className="text-muted" style={{ fontSize: '0.9em' }}>{cell.row.original.email}</div>
                                </Stack>
                            </Stack>
                        </Fragment>
                    )
                }
            }
        },
        {
            Header: "Courier",
            accessor: "courier_service"
        },
        {
            Header: "Date",
            accessor: "order_date",
            Cell: ({ cell }) => (
                <Stack direction="vertical" gap={0}>
                    <div style={{ lineHeight: 1 }}>{moment(cell.value).format('YYYY-MM-DD')}</div>
                    <div className="text-muted" style={{ fontSize: 12 }}>{moment(cell.value).format('HH:mm:ss')}</div>
                </Stack>
            )
        },
        {
            Header: "Total",
            accessor: "total_payment",
            Cell: ({ cell }) => (global.IDR.format(cell.value))
        },
        {
            Header: "Payment",
            accessor: "payment_method"
        },
        {
            Header: "Resi",
            accessor: "no_resi"
        },
        {
            Header: "Status",
            accessor: "order_state",
            Cell: ({ cell }) => {
                return cell.value ? <Label className="text-capitalize" content={cell.value} size="mini" style={{ backgroundColor: cell.row.original.state_color }} /> : <></>
            }
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Fragment>
                    <Button.Group size="mini" basic>
                        <Popup content="View Detail" size="mini" trigger={<Button icon="search" size="mini" onClick={() =>
                            navigate(`/order_detail/`, { state: cell.row.original })
                        } />} />
                        {
                            cell.row.values.order_state !== 'CANCEL' ? 
                            <Popup content="Invoice" trigger={<Button icon="file alternate outline" onClick={() => navigate('/order_invoice', { state: cell.row.original })} />} size="mini" />
                            : <></>
                        }
                        {cell.row.values.order_state === 'IN DELIVERY' && !cell.row.values.no_resi ?
                            <Popup content="Generate Resi" trigger={<Button icon="magic"
                                onClick={() => getDetail(cell.row.original.id_order, cell.row.original.id_customer)} />}
                                size="mini" />
                            : <></>
                        }
                        {cell.row.values.order_state === 'IN DELIVERY' ?
                            <Popup content="Delivery Label" trigger={<Button icon="sticky note outline"
                                onClick={() => navigate('/order_delivery', { state: cell.row.original })} />}
                                size="mini" />
                            : <></>
                        }
                        {
                            cell.row.values.order_state === 'IN DELIVERY' || cell.row.values.order_state === 'ARRIVE AT DESTINATION' ?
                                !cell.row.original.isDownloaded ?
                                    <Popup content="Download Order" trigger={<Button icon="download"
                                        onClick={() => createFileTxt(cell.row.original)} />}
                                        size="mini" />
                                    :
                                    <Popup content="Already Downloaded" trigger={<Button icon="check circle outline"
                                        onClick={() => createFileTxt(cell.row.original)} />}
                                        size="mini" />
                                : <></>
                        }
                    </Button.Group>
                </Fragment>
            )
        },
    ], [])

    const columnDefs = [
        {
            headerName: '#',
            field: 'no',
            valueGetter: (params) => params.node.rowIndex + 1,
            maxWidth: 60,
            minWidth: 60,
            suppressMenu: true
        },
        {
            headerName: 'Order ID',
            field: 'id_order',
            cellStyle: { display: 'flex', alignItems: 'center' },
            maxWidth: 125,
            minWidth: 125
        },
        {
            headerName: 'Customer',
            field: 'customer',
            cellRenderer: rendererCustomer,
            cellStyle: { display: 'flex', alignItems: 'center' },
            minWidth: 300
        },
        {
            headerName: 'Date',
            field: 'order_date',
            cellStyle: { display: 'flex', alignItems: 'center' },
            maxWidth: 170,
            minWidth: 170
        },
        {
            headerName: 'Courier',
            field: 'courier_service',
            cellStyle: { display: 'flex', alignItems: 'center' },
            maxWidth: 125,
            minWidth: 125,
        },
        {
            headerName: 'Total',
            field: 'total_payment',
            cellStyle: { display: 'flex', alignItems: 'center' },
            maxWidth: 125,
            minWidth: 125,
            valueGetter: (params) => (global.IDR.format(params.data.total_payment))
        },
        {
            headerName: 'Method',
            field: 'payment_method',
            cellStyle: { display: 'flex', alignItems: 'center' },
            maxWidth: 125,
            minWidth: 125,
            valueGetter: (params) => params.data.payment_method ? params.data.payment_method.toUpperCase() : ''
        },
        {
            headerName: 'Resi',
            field: 'no_resi',
            cellStyle: { display: 'flex', alignItems: 'center' },
            maxWidth: 170,
            minWidth: 170,
        },
        {
            headerName: 'Status',
            field: 'order_state',
            cellStyle: { display: 'flex', alignItems: 'center' },
            maxWidth: 200,
            minWidth: 200,
            cellRenderer: rendererStatus
        },
        {
            headerName: 'Action',
            field: 'action',
            cellStyle: { display: 'flex', alignItems: 'center' },
            maxWidth: 200,
            minWidth: 200,
            cellRenderer: rendererAction
        },

    ]

    const getResiNumber = async (order) => {
        const param =
        {
            receiver_contact: order.customer.recipient,
            receiver_name: order.customer.recipient,
            receiver_addr: order.customer.address_detail,
            receiver_addr_dua: order.customer.address_detail_dua,
            receiver_city: order.customer.city,
            receiver_zip: order.customer.zip,
            receiver_region: order.customer.district,
            receiver_phone: order.customer.phone,
            service_code: order.customer.service_display,
            destination_code: order.customer.desti_code,
            destination_description: order.customer.city,
            weight: order.customer.total_weight,
            qty: order.customer.total_qty,
            goods_amount: order.customer.total_price,
            delivery_price: order.customer.service_cost,
            insurance: "Y",
            insurance_amount: order.customer.asuransi,
            goods_desc: "Sport Equipment",
            order_number: order.customer.id_order,
            origin: 'BDO10000',
        }
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/courier.php/?index=1`,
            headers: { 'Content-Type': 'text/plain' },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    getOrder(dateRange)
                    setLoadingHide(true)
                } else if (res.data.state === 300) {
                    alert('Error : ' + `${res.data.message} ${res.data.carrier_response.error}`)
                }
            })
    }

    const statusColor = (status) => {
        switch (status) {
            case 'SETTLEMENT':
                return 'lightblue'
                break
            case 'CANCEL':
                return 'pink'
                break
            case 'IN DELIVERY':
                return 'lightgreen'
                break
            case 'ARRIVE AT DESTINATION':
                return '#d5c9f0'
                break
            default:
                return '#e8e8e8'
        }
    }

    const getBooking = async (date) => {
        let data = {
            date_start: date ? date[0] : "undefined",
            date_end: date ? date[1] : "undefined",
        }

        await axios({
            method: "POST",
            url: `${global.SRV}/api_hoops_cms/controler/order.php/?index=8`,
            headers: { 'Content-Type': 'text/plain' },
            data: data,
        })
            .then(res => {
                console.log(res.data.data)
                if (res.data.state === 200) {
                    setOnBooking(res.data.data)
                }
            })
    }


    const getOrder = async (date) => {
        setLoading(true)
        let data = {
            date_start: date ? date[0] : "undefined",
            date_end: date ? date[1] : "undefined",
        }
        await axios({
            method: "POST",
            url: `${global.SRV}/api_hoops_cms/controler/order.php/?index=1`,
            headers: { 'Content-Type': 'text/plain' },
            data: data,

        })
            .then(res => {
                if (res.data.state === 200) {
                    let data = res.data.data
                    let jmlSetle = 0
                    let jmlInDel = 0
                    setOrder(data)
                    setLoading(false)
                    res.data.data.map((item, i) => {
                        if (item.order_state.toUpperCase() === 'SETTLEMENT') {
                            jmlSetle += 1
                        }
                    })
                    res.data.data.map((item, i) => {
                        if (item.order_state.toUpperCase() === 'IN DELIVERY') {
                            jmlInDel += 1
                        }
                    })
                    setSetlementOrder(jmlSetle)
                    setInDelivery(jmlInDel)
                }
            })
    }

    const getDetailForTxt = async (id_order, id_customer) => {
        let param = {
            id_order: id_order,
            id_customer: id_customer
        };

        try {
            const response = await axios({
                method: "POST",
                url: `${global.SRV}api_hoops_cms/controler/order.php/?index=2`,
                headers: { 'Content-Type': 'application/json' },
                data: param
            });

            if (response.data.state === 200) {
                // Return the data
                return response.data.data;
            }
        } catch (error) {
            // Handle errors
            console.error('Error:', error.message);
            throw error; // Propagate the error
        }
    };

    const getDetail = async (id_order, id_customer) => {
        let param = {
            id_order: id_order,
            id_customer: id_customer
        }

        setLoadingHide(false)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/order.php/?index=2`,
            headers: { 'Content-Type': 'text/plain' },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    setOrderDetail(res.data.data)
                    getResiNumber(res.data.data)
                }
            })
    }

    const handleChangeDate = (e) => { console.log(e[0])
        setDateRange(e)
        window.sessionStorage.setItem('startOrd', JSON.stringify(e[0]))
        window.sessionStorage.setItem('endOrd', JSON.stringify(e[1]))
    }
    
    const downloadFile = () => {
        const timestamp = new Date().getTime(); // Mendapatkan timestamp saat ini
        const fileUrl = `${global.SRV}download/order.xlsx?timestamp=${timestamp}`;
    
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'order.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const exportOrder = async (date) => {
        setExportLoader(true)
        let data = {
            date_start: date.length > 0 ? date[0] : "undefined",
            date_end: date.length > 0 ? date[1] : "undefined",
        }
        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/export.php/?index=1`,
            headers: { 'Content-Type': 'application/json' },
            data: data
        })
            .then(res => {
                if (res.data.state === 200) {
                    downloadFile()
                }
                setExportLoader(false)
            })
    }

    const successTrx = async (date) => {
        let data = {
            date_start: date ? date[0] : "undefined",
            date_end: date ? date[1] : "undefined",
        }
        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/order.php/?index=6`,
            headers: { 'Content-Type': 'text/plain' },
            data: data
        })
            .then(res => {
                console.log(res.data)
                if (res.data.state === 200) {
                    setTotalOrder(res.data.data)
                }
            })
    }

    const showBooking = () => (
        setModalBooking(true)
    )

    const handleCloseBooking = () => (
        setModalBooking(false)
    )

    useEffect(() => {
        getOrder(dateRange)
        successTrx(dateRange)
        getBooking(dateRange)
    }, [dateRange])

    useEffect(() => {
        if (order.length > 0) {
            const intervalId = setInterval(() => {
                getOrder(dateRange)
            }, 60000)
            return () => clearInterval(intervalId)
        }
    }, [])

    // useEffect(() => { 
    //     if (searchValue !== ''){
    //         let newFilter = order.filter(i => i.customer &&
    //             i.customer.toLowerCase().includes(searchValue.toLowerCase(searchValue)) ||
    //             i.order_state.toLowerCase().includes(searchValue.toLowerCase(searchValue)) ||
    //             i.id_order.toLowerCase().includes(searchValue.toLowerCase(searchValue))
    //         )
    //         setOrder(newFilter)
    //     } else { 
    //         getOrder(dateRange)
    //     }
    // }, [searchValue])


    return (
        <Container fluid>
            <Modal show={modalBooking} onHide={handleCloseBooking} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5">Product on Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table" style={{ fontSize: '0.8em' }}>
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>Product</td>
                                <td>Size</td>
                                <td>Qty</td>
                                <td>Added</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                onBooking.map((item, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                            <Stack direction="horizontal" gap={2}>
                                                <div><Image src={item.image} width={45} height={40} rounded className="border" /></div>
                                                <Stack direction="vertical" gap={0}>
                                                    <div>{item.id_product}</div>
                                                    <Popup content={item.item_name} size="tiny" trigger={
                                                        <div className="text-truncate" style={{ width: '30em' }}>{item.item_name}</div>
                                                    } />
                                                </Stack>
                                            </Stack>
                                        </td>
                                        <td>{item.size}</td>
                                        <td>{item.qty}</td>
                                        <td>{item.time}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
            <Row className="mt-1 mb-3">
                <Col md={6}>
                    <div className="text-facebook h3 mb-0">Transaction</div>
                    <div className="text-muted">This is transaction list that has been created by customer</div>
                </Col>
                <Col md={6} className="d-flex align-item-middle justify-content-end">
                    <Stack direction="horizontal" gap={2} hidden={loadingHide} >
                        <Icon loading name="spinner" />
                        Loading Resi
                    </Stack>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col md={2} className="mb-2">
                    <DateRangePicker
                        format="yyyy-MM-dd"
                        placement="bottomStart"
                        cleanable={true}
                        defaultValue={dateRange}
                        value={dateRange}
                        onOk={handleChangeDate}
                        onClean={() => setDateRange([
                            new Date(moment().startOf('month')),
                            new Date(moment()),
                        ])}
                    />
                </Col>
                <Col md={10} className="mb-2">
                    <Stack direction="horizontal" gap={1}>
                        <div className="me-2">
                            <Popup content="Total Revenue" trigger={
                                <Label as='a' size="large" basic color="black">
                                    <Icon name="money" /> {global.IDR.format(totalOrder)}
                                </Label>
                            } />
                        </div>
                        <div className="me-2">
                            <Popup content="Number of orders that have been paid for but have not been processed" trigger={
                                <Label basic size="large" style={{ backgroundColor: '#66CD7C' }}>Settlement&nbsp;<span style={{ border: 'solid 1px black', borderRadius: 50, padding: '0px 5px 0px 5px' }}>{setlementOrder}</span></Label>
                            }
                            />
                        </div>
                        <div className="me-2">
                            <Popup content="Number of orders that have been paid for and have been processed" trigger={
                                <Label basic size="large" style={{ backgroundColor: '#53c8c5' }}>In Delivery&nbsp;<span style={{ border: 'solid 1px black', borderRadius: 50, padding: '0px 5px 0px 5px' }}>{inDelivery}</span></Label>
                            }
                            />
                        </div>
                        <div className="me-2">
                            <Popup content="Number of items in the cart and in the booking which stock will be affected by this" trigger={
                                <Label
                                    basic
                                    size="large"
                                    style={{ backgroundColor: '#9798', cursor: 'pointer' }}
                                    onClick={showBooking}
                                >
                                    On Booking&nbsp;<span style={{ border: 'solid 1px black', borderRadius: 50, padding: '0px 5px 0px 5px' }}>{onBooking.length}</span>
                                </Label>
                            }
                            />
                        </div>
                    </Stack>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={12} lg={12} md={12} sm={12}>
                    {/* <Table columns={columns} data={order} pageRow={10} /> */}
                    <div className="me-2 text-end">
                        <Button content="Export Order & Detail" onClick={() => exportOrder(dateRange)} loading={exportLoader} size="mini" color="facebook" icon="share square"/>
                    </div>
                    <AGtable
                        rowData={order ? order : undefined}
                        columnDefs={columnDefs}
                        loading={loading}
                        exportFileName="data_order"
                        exportData={false}
s                    />
                </Col>
            </Row>
        </Container>
    )
}

export default Order