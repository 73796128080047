

import React, {useState, useEffect} from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Button} from "semantic-ui-react";
import axios from "axios";


const ColorAdd = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [colorName, setColorName] = useState('')
    const [color1, setColor1] = useState('#FFFFFF')
    const [color2, setColor2]  = useState('#FFFFFF')
    const [color3, setColor3] = useState('#FFFFFF')

    const handleSubmit = (e) => {
        e.preventDefault()
        const param = {
            name : colorName,
            color1 : color1, 
            color2 : color2,
            color3 : color3,
        }
        
        axios({
            url :"https://hoopsdevteam.com/api_web/controler/cms.php/?index=27", 
            method:"POST",
            headers: {'Content-Type': 'text/plain'},
            data :param
        })
        .then(res => {  
            if (res.data.state === 200){
               navigate('/color')
            }
        })
    }

   
    return(
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Add Product Color</div>
                <div className="text-muted">Adding new color for product attribute color</div>
            </Row>
            <Divider />
             <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={8} md={10} sm={12}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Color Name</Form.Label>
                            <Form.Control
                                type="text"
                                className="mb-3"
                                onChange={(e) => setColorName(e.target.value) }
                                required
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Color Primary</Form.Label>
                            <Form.Control 
                                type="color"  
                                className="mb-3" 
                                title="Select Color"
                                defaultValue={color1}
                                onChange={(e) => setColor1(e.target.value) }
                                required
                            />
                            <Form.Label>Color Secondary</Form.Label>
                            <Form.Control 
                                type="color"  
                                className="mb-3" 
                                title="Select Color"
                                defaultValue={color2}
                                onChange={(e) => setColor2(e.target.value) }
                                required
                            />
                            <Form.Label>Color Accent</Form.Label>
                            <Form.Control 
                                type="color"  
                                className="mb-3" 
                                title="Select Color"
                                defaultValue={color3}
                                onChange={(e) => setColor3(e.target.value) }
                                required
                            />
                        </Form.Group>
                        <Button content="Save Color" color="facebook" className="mt-5"/>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ColorAdd