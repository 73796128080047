import React from "react";
import { useState, useEffect } from "react";
import { Col, Container, Image, Row, Stack, Table } from "react-bootstrap";
import { Checkbox, Divider, List, Radio, Tab } from "semantic-ui-react";
import axios from "axios";
import emptyImg from '../../assets/no_data.png' 


export const Courier = () => {
    const [panes, setPanes] = useState([])
    const [courier, setCourier] = useState([])
    const [service, setService] = useState([])

    const handleClickActiveCourier = (idCourier) => {
        const param = {
            id_courier : idCourier
        }

        axios({
            method: "POST",
            url: `${global.SRV}/api_hoops_cms/controler/courier.php/?index=6`,
            headers: { 'Content-Type': 'text/plain'},    
            data:param
        })
        .then (res => {
            if (res.data.state === 200){
                alert("Selected service is changed")
            }else{
                alert("failed to change" + ' - ' + res.data.error)
            }
        })
    }

    const handleClickCheck = (idService, idCourier) => { 
        const param = {
            id_service: idService,
            id_courier: idCourier,
        }
        
        axios({
            method: "POST",
            url: `${global.SRV}/api_hoops_cms/controler/courier.php/?index=5`,
            headers: { 'Content-Type': 'text/plain'},    
            data:param
        })
        .then (res => {
            if (res.data.state === 200){
                alert("Selected service is changed")
            }else{
                alert("failed to change" + res.data.error)
            }
        })
    }

    const getCourier = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}/api_hoops_cms/controler/courier.php/?index=3`,
            headers: { 'Content-Type': 'text/plain' },    
        })
        .then (res => {
            if (res.data.state === 200){
                setCourier(res.data.data)
            }
        })
    }

      
    useEffect(() => {
        const getCourierService = async (id) => {
            await axios({
                method: "GET",
                url: `${global.SRV}/api_hoops_cms/controler/courier.php/?index=2`,
                headers: { 'Content-Type': 'text/plain'},    
            })
            .then (res => {
                 if (res.data.state === 200){
                    setService(res.data.data)
                 }
            })
        }
        

        getCourierService()
        getCourier()
    },[])
   
    useEffect(() => {
        let arr = []
        courier.map((itemC,iC) => {
            arr.push(
                { 
                    menuItem: {
                        key:itemC.id_courier, 
                        content:<Stack direction="horizontal" gap={2}>
                                <Image src={itemC.logo} height={40} width={40} className="border p-1"/>
                                <Stack direction="vertical" gap={2}>
                                    <div>{itemC.name}</div>
                                    <div className="text-muted small">{itemC.description}</div>
                                </Stack>
                                
                            </Stack>
                    }, 
                    render: () => {
                        let filServ = service.filter(o => o.id_courier === itemC.id_courier)
                        return (
                            <Tab.Pane key={iC}>
                                <div className="mb-3 mt-3">
                                    <Checkbox 
                                        label="Display courier to customer (beta)" 
                                        defaultChecked={itemC.isActive === '1' ? true : false}
                                        onChange={() => handleClickActiveCourier(itemC.id_courier)}
                                    />
                                </div>
                                <Table className="w-100">
                                    <thead className="bg-light">
                                        <tr>
                                            <td>#</td>
                                            <td>Service</td>
                                            <td>Active</td>
                                        </tr>
                                    </thead>
                                    <tbody>                                       
                                    {
                                        filServ.length > 0 ?
                                        filServ.map((itemS,iS) => {
                                            return (
                                                <tr>
                                                    <td>{iS+1}</td>
                                                    <td>
                                                        <Stack direction="vertical" gap={0}>
                                                            <div>{itemS.service_display}</div>
                                                            <div className="text-muted">{itemS.estimasi}</div>
                                                        </Stack>
                                                    </td>
                                                    <td>
                                                        <Checkbox 
                                                            key={iS}
                                                            toggle 
                                                            defaultChecked={itemS.is_active === 'Y' ? true : false} 
                                                            style={{ transform: 'scale(.7)' }}
                                                            onChange={(e) => handleClickCheck(itemS.id, itemC.id_courier,e)}
                                                        />
                                                        </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <td colSpan={3} className="text-center border-bottom">
                                            <Image src={emptyImg} height={120} width={120}/>
                                        </td>
                                    }
                                    </tbody>
                                </Table>
                            </Tab.Pane>
                        )
                    }
                }
            )
        })
        setPanes(arr)
    },[courier])

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Courier</div>
                <div className="text-muted">All available courier</div>
            </Row>
            <Divider />
  
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={12} lg={12} md={12} sm={12}>
                    <Tab  panes={panes}/>
                </Col>
            </Row>
        </Container>
    )
}