import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Divider, Button, Form } from "semantic-ui-react";
import axios from "axios";
import DateRangePicker from 'rsuite/DateRangePicker';
import moment from "moment/moment";

const CarouselBannerAdd = () => {
    const navigate = useNavigate()
    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [image, setImage] = useState([])
    const [url, setUrl] = useState('')
    const [extUrl, setExtUrl] = useState('')
    const [position, setPosition] = useState('')

    const dateNow = new Date();

    const [date, setDate] = useState([
        new Date(),
        new Date(dateNow.setDate(dateNow.getDate() + 7))
    ])

    const handleImageChange = (e) => {
        let file = e.target.files
        setImage(file[0])
    }

    const handleSubmit = () => {
        const param = new FormData()
        param.append("title", title)
        param.append("desc", desc)
        param.append("image", image)
        param.append("date", JSON.stringify(date))
        param.append("url", url)
        param.append("external_url", extUrl)
        param.append("position", position)
        
        axios({
            method:"POST",
            url:`${global.SRV}api_hoops_cms/controler/carousel.php/?index=2`,
            headers:{'Content-Type': 'text/plain'},
            data:param
        })
        .then (res => { console.log(res.data)
            if (res.data.state === 200){
                navigate('/carousel_banner')
            }
        })
    }

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Add Carousel Banner</div>
                <div className="text-muted">Adding new item image for carousel banner</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={10} md={12} sm={12} className="mb-3">
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Input type="text" label="Title" onChange={(e,{value}) => setTitle(value)} required/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input type="text" label="Description" onChange={(e,{value}) => setDesc(value)}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input type="text" label="Url" onChange={(e,{value}) => setUrl(value)}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input type="text" label="External Url" defaultValue={extUrl} onChange={(e,{value}) => setExtUrl(value)}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input type="text" label="Position" onChange={(e,{value}) => setPosition(value)} required/>
                        </Form.Field>
                        <Form.Input
                            label="Image"
                            type="file"
                            icon="image"
                            className="mb-0 mt-3"
                            required
                            onChange={(e) => handleImageChange(e)}
                        />
                        <small className="text-muted">Images must be in *.png format and file size no more than 500kb</small>
                        <Form.Field className="mb-3 mt-2">
                            <label>Show Date</label>
                            <DateRangePicker 
                                defaultValue={date}
                                format="yyyy-MM-dd HH:mm:ss" 
                                appearance="default" 
                                size="lg" 
                                style={{width:"100%"}} 
                                placement="topStart"
                                onOk={setDate}
                                required
                            />
                        </Form.Field>
                        <Form.Field className="mt-4">
                            <Form.Button content="Save Item" color="facebook"/>
                        </Form.Field>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default CarouselBannerAdd