import axios from "axios";
import React, {Fragment, useEffect, useState} from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Checkbox, Button, Dropdown, Popup } from "semantic-ui-react";
import Table from "../component/Table";
import Pesan from "../component/Pesan";

const CategorySub = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [categorySub, setCategorySub] = useState([])
    const [showAlert, setShowAlert] = useState(false)

    const getSubCategory = async (id) => {
        await axios.get(`https://hoopsdevteam.com/api_web/controler/cms.php/?index=10&id_category=${id}`)
            .then(res => {
                if (res.data.state === 200) {
                    setCategorySub(res.data.data)
                }
            })
    }

    const handleClickNew = () => {
        navigate('/category_sub_add', { state: location.state })
    }

    const columns = React.useMemo(() => [
        {
            Header: "ID",
            accessor: "id_category_child"
        },
        {
            Header: "ID Parent",
            accessor: "id_category"
        },
        {
            Header: "Name",
            accessor: "category_name"
        },
        {
            Header: "Position",
            accessor: "position"
        },
        {
            Header: "Image",
            accessor: "header_image",
            Cell: ({ cell }) => (
                (cell.value) ? <Image src={cell.value} width={35} height={25} rounded /> : <Image src="https://via.placeholder.com/500x400.png" width={35} height={25} rounded />
            )
        },
        {
            Header: "Thumbnail",
            accessor: "thumbnail_image",
            Cell: ({ cell }) => (
                (cell.value) ? <Image src={cell.value} width={35} height={25} rounded /> : <Image src="https://via.placeholder.com/500x400.png" width={35} height={25} rounded />
            )
        },
        {
            Header: "Active",
            accessor: "category_state",
            Cell: ({ cell }) => (
                <Checkbox
                    toggle
                    checked={cell.value === "1" ? true : false}
                    style={{ transform: 'scale(.7)', width: "12px" }}
                    onChange={(e) => handleChangeDisplay(e, cell.row.values)}
                />
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Fragment>
                    <Popup content="Edit" size="mini" trigger={<Button basic icon="edit outline" size="mini" onClick={() => navigate('/category_sub_edit/', {state:cell.row.original})}/>}/>
                </Fragment>
            )
        }
    ], [])

    const handleChangeDisplay = (e, value) => {
        updateDisplay(value.id_category_child)
    }

    const updateDisplay = (id) => {
        let data = {
            id_category_child : id
        }
        axios.post("https://hoopsdevteam.com/api_web/controler/cms.php/?index=15", data)
            .then(res => {
                if (res.data.state === 200){
                    getSubCategory(location.state.ID)
                    setShowAlert(true)
                }
            })
    }

    useEffect(() => {
        getSubCategory(location.state.ID)
    }, [location.state.ID])
    
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Sub Category</div>
                <div className="text-muted">This Sub categories that have been made based on main category</div>
            </Row>
            <Divider />
            <Row className="mb-3">
                <Col lg={12}>
                    <Pesan variant="success" show={showAlert} setShowAlert={setShowAlert} content="update sub category state success" header="Success"/>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12} xl={12} className="mb-3">
                    <div className="fs-6 mb-0">This sub category for main category : <span className="text-facebook fw-bold">{location.state.category_name}</span></div>
                </Col>
                <Col md={12} lg={12} xl={12} className="mb-3 d-flex justify-content-end">
                    <Button content="New Sub Category" color="facebook" onClick={() => handleClickNew()} />
                </Col>
                <Col md={12} lg={12} xl={12} className="mb-3">
                    <Table columns={columns} data={categorySub} />
                </Col>
            </Row>
        </Container>
    )
}

export default CategorySub