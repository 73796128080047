import React from "react";
import { useState } from "react";
import { Container, Row, Col, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Label, Popup } from "semantic-ui-react";
import axios from "axios"
import { useEffect } from "react";
import { useMemo } from "react";
import Table from "../component/Table";
import { Fragment } from "react";

const Voucher = () => {
    const navigate = useNavigate()
    const [voucher, setVoucher] = useState([])

    const getVoucher = async () => {
        await axios({
            method:"GET",
            url:`${global.SRV}api_hoops_cms/controler/voucher.php/?index=1`
        })
        .then (res => {
            if (res.data.state === 200){
                setVoucher(res.data.data)
            }
        })
    }

    const columns = useMemo(() => [
        {
            Header : "ID",
            accessor : "id_voucher"
        },
        {
            Header : "Name",
            accessor : "name",
            Cell : ({cell}) => {
                return (
                    <Stack direction="vertical" gap={0}>
                        <div>{cell.row.original.name}</div>
                        <Popup 
                            size="mini"
                            content={cell.row.original.description}
                            trigger={<div style={{fontSize:'0.8em', width:'150px'}} className="text-muted text-truncate">{cell.row.original.description}</div>}
                        />
                    </Stack>
                )
            }
        },
        {
            Header : "Code",
            accessor : "voucher_code",
        },
        {
            Header : "Type",
            accessor : "type",
            Cell : ({cell}) => {
                if(cell.value == 'N'){
                    return (
                        <Stack direction="vertical">
                            <div>Nominal</div>
                            <div>{global.IDR.format(cell.row.original.nominal)}</div>
                        </Stack>
                    )
                }
                if(cell.value == 'D'){
                    return (
                        <Stack direction="vertical">
                            <div>Percent</div>
                            <div>{cell.row.original.percent}%</div>
                        </Stack>
                    )
                }
                if(cell.value == 'O'){
                    return (
                        <Stack direction="vertical">
                            <div>Ongkir</div>
                            <div>{global.IDR.format(cell.row.original.nominal)}</div>
                        </Stack>
                    )
                }
            }
        },
        {
            Header : "Percent",
            accessor : "percent"
        },
        {
            Header : "Nominal",
            accessor : "nominal",
            Cell : ({cell}) => global.IDR.format(cell.value)
        },
        {
            Header : "Minimum Trx.",
            accessor : "minimum_trx",
            Cell : ({cell}) => global.IDR.format(cell.value)
        },
        {
            Header : "Combinable",
            accessor : "is_combine"
        },
        {
            Header : "Expose",
            accessor : "show"
        },
        {
            Header : "Display Date",
            accessor : "start_date",
            Cell : ({cell}) => (
                <Stack direction="vertical">
                    <div style={{fontSize:'0.9em',}}>Start : <span style={{color:'green', fontWeight:'bold'}}>{cell.row.original.start_date}</span></div>
                    <div style={{fontSize:'0.9em'}}>End : <span style={{color:'maroon', fontWeight:'bold'}}>{cell.row.original.end_date}</span></div>
                </Stack>
            )
        },       
        {
            Header : "Alocation",
            accessor : "customer_type",
            Cell : ({cell}) => (cell.value === "M" ? 'Member' : 'Customer')
        },
        {
            Header : "Action",
            accessor : null,
            Cell : ({cell}) => { 
                return (
                    <Fragment>
                        <Button.Group size="mini" basic>
                            <Popup content="Edit" trigger={<Button icon="edit"  size="mini" onClick={() => navigate('/voucher_edit', {state:cell.row.original})} />} />
                            {
                                cell.row.original.is_specific_product == 'Y' &&
                                <Popup content="View Included Product" trigger={
                                        <Button icon="box" size="mini" onClick={() => navigate('/voucher_product', {state:cell.row.original})}/>
                                    }
                                />
                            }
                        </Button.Group>
                    </Fragment>
                )
                }
        },
    ],[])

    useEffect(() => {
        getVoucher()
    },[])
  
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Voucher</div>
                <div className="text-muted">This voucher list that can be used by customer or member</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={12} lg={12} md={12} sm={12} className="d-flex justify-content-start mb-3">
                    <Button content="New Voucher" color="facebook" onClick={() => navigate('/voucher_add')} />
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <Table columns={columns} data={voucher} pageRow={10}/>
                </Col>
            </Row>
        </Container>
    )
}

export default Voucher