import React from "react";
import { useEffect, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Divider, Button, Label, Popup } from "semantic-ui-react";
import axios from "axios"
import { useState } from "react";
import { useMemo } from "react";
import Table from "../component/Table";

const VoucherSpecial = () => {
    const navigate = useNavigate()
    const [voucher , setVoucher] = useState([])

    const getVoucher = async () => {
        await axios.get(`${global.SRV}api_hoops_cms/controler/voucher.php/?index=4`)
        .then (res => { console.log(res.data.data)
            if (res.data.state === 200){
                setVoucher(res.data.data)
            }
        })
    }

    const columns = useMemo(() => [
        {
            Header:"ID",
            accessor:"id_voucher"
        },
        {
            Header:"ID Customer",
            accessor:"id_customer",
        },
        {
            Header:"Customer",
            accessor:"customer_name",
        },
        {
            Header:"Email",
            accessor:"email",
        },
        {
            Header:"Voucher",
            accessor:"voucher_name",
        },
        {
            Header:"Description",
            accessor:"voucher_desc",
        },
        {
            Header:"Code",
            accessor:"voucher_code",
        },
        {
            Header:"Start",
            accessor:"start_date",
        },
        {
            Header:"End",
            accessor:"end_date",
        },
        {
            Header:"Type",
            accessor:"discount_type",
            Cell : ({cell}) => (cell.value === "N" ? <Label content="Rp"/> : <Label content="%"/> )
        },
        {
            Header:"Nominal",
            accessor:"discount_value",
        },
        {
            Header:"Percent",
            accessor:"discount_percent",
        },
        {
            Header:"Dsc. Max",
            accessor:"discount_max",
        },
        {
            Header:"Mimimum Trx",
            accessor:"minimum_trx",
        },
        {
            Header:"Expose",
            accessor:"show",
        },
        {
            Header:"Combinable",
            accessor:"is_combine",
        },
        {
            Header : "Action",
            accessor : null,
            Cell : ({cell}) => (
                <Fragment>
                    <Button.Group size="mini" basic>
                        <Popup content="Edit" trigger={<Button icon="edit"  size="mini" onClick={() => navigate('/voucher_special_edit', {state:cell.row.values})} />} />
                        {/* <Popup content="Delete" trigger={<Button icon="eraser" size="mini"/>} /> */}
                    </Button.Group>
                </Fragment>
            )
        },
    ],[])

    useEffect(() => {
        getVoucher()
    },[])

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Special Voucher</div>
                <div className="text-muted">This voucher is specifically for certain customers or member</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={12} lg={12} md={12} sm={12} className="d-flex justify-content-start mb-3">
                    <Button content="New Voucher" color="facebook" onClick={() => navigate('/voucher_special_add')} />
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <Table columns={columns} data={voucher} pageRow={10} hideColumn={['id_customer']}/>
                </Col>
            </Row>
        </Container>
    )
}

export default VoucherSpecial