import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Divider, Button, Popup, Checkbox } from "semantic-ui-react";
import axios from 'axios'
import Table from "../component/Table";
import Swal from 'sweetalert2'
import Pesan from "../component/Pesan";

const CarouselBanner = () => {
    const navigate = useNavigate()
    const [carousel, setCarousel] = useState([])
    const [showAlert, setShowAlert] = useState(false)

    const handleChangeEnable = (e, value) => {
        updateState(value.id_carousel)
    }


    const deleteCarousel = (id) => {
        const param = { id_carousel: id }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3b5998',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios({
                    method: "POST",
                    url: `${global.SRV}api_hoops_cms/controler/carousel.php/?index=4`,
                    headers: { 'Content-Type': 'text/plain' },
                    data: param
                })
                    .then(res => {
                        if (res.data.state === 200) {
                            getCarousel()
                            setShowAlert(true)
                        }
                    })
            }
        })
    }

    const updateState = (id) => {
        let param = { id_carousel: id }

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/carousel.php/?index=3`,
            headers: { 'Content-Type': 'text/plain' },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    getCarousel()
                }
            })
    }

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_carousel"
        },
        {
            Header: "Title",
            accessor: "title"
        },
        {
            Header: "Description",
            accessor: "desc"
        },
        {
            Header: "Image",
            accessor: "image",
            Cell: ({ cell }) => (cell.value !== '' ? <Image src={cell.value} width={50} height={50} fluid /> : <Image src="https://via.placeholder.com/500x400.png" width={50} height={50} fluid />)
        },
        {
            Header: "Position",
            accessor: "posisi",
        },
        {
            Header: "Enabled",
            accessor: "is_active",
            Cell: ({ cell }) => {
                return (
                    <Checkbox
                        toggle
                        defaultChecked={cell.value === 'Y' ? true : false}
                        style={{ transform: 'scale(.7)' }}
                        onChange={(e) => handleChangeEnable(e, cell.row.values)}
                    />
                )
            }
        },
        {
            Header: "Show",
            accessor: "start_show"
        },
        {
            Header: "End",
            accessor: "end_show"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => { console.log(cell)
                return (
                <Fragment>
                    <Button.Group size="mini" basic>
                        <Popup content="Edit" trigger={<Button icon="edit" size="mini" onClick={() => navigate('/carousel_banner_edit', { state: cell.row.original })} />} />
                        <Popup content="Delete" trigger={<Button icon="eraser" size="mini" onClick={() =>
                            deleteCarousel(cell.row.values.id_carousel)
                        } />} />
                    </Button.Group>
                </Fragment>
                )
            }
        },
    ], [])

    const getCarousel = async () => {
        await axios.get(`${global.SRV}api_hoops_cms/controler/carousel.php/?index=1`)
            .then(res => {
                if (res.data.state === 200) {
                    setCarousel(res.data.data)
                }
            })
    }

    useEffect(() => {
        getCarousel()
    }, [])

    return (
        <Fragment>
            <Container fluid>
                <Row className="mt-1 mb-3">
                    <div className="text-facebook h3 mb-0">Carousel Banner</div>
                    <div className="text-muted">This Carousel banner list that will appear at the top of the website's main page</div>
                </Row>
                <Divider />
                <Pesan variant="success" setShowAlert={setShowAlert} show={showAlert} content="Success delete selected item" header="Item deleted" />

                <Row className="d-flex justify-content-center mb-3 mt-3">
                    <Col xl={12} lg={12} md={12} sm={12} className="d-flex justify-content-start mb-3">
                        <Button content="New Carousel Item" color="facebook" onClick={() => navigate('/carousel_banner_add')} />
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Table columns={columns} data={carousel} pageRow={10} />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default CarouselBanner