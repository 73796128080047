import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Divider, Form, Button } from "semantic-ui-react";
import DateRangePicker from 'rsuite/DateRangePicker';
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const CarouselBannerEdit = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [idCarousel, setIdCarousel] = useState('')
    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [image, setImage] = useState([])
    const [url, setUrl] = useState('')
    const [extUrl, setExtUrl] = useState('')
    const [position, setPosition] = useState('')
   
    const [date, setDate] = useState([
        new Date(location.state.start_show),
        new Date(location.state.end_show)
    ])

    const handleImageChange = (e) => {
        let file = e.target.files
        setImage(file[0])
    }

    const handleSubmit = () => {
        const param = new FormData()
        param.append("id_carousel", idCarousel)
        param.append("title", title)
        param.append("desc", desc)
        param.append("image", image)
        param.append("date", JSON.stringify(date))
        param.append("url", url)
        param.append("position", position)
        param.append("external_url", extUrl)
        console.log(url)
        axios({
            method:"POST",
            url:`${global.SRV}api_hoops_cms/controler/carousel.php/?index=5`,
            headers:{'Content-Type': 'text/plain'},
            data:param
        })
        .then (res => { console.log(res.data)
            if (res.data.state === 200){
                navigate('/carousel_banner')
            } else if (res.data.state === 300) {
                alert(res.data.message + ' : ' + res.data.file_type)
            } else {
                alert(res.data.error)
            }
        })
    }

    useEffect(() => { 
        let data = location.state
        setTitle(data.title)
        setDesc(data.desc)
        setImage(data.image)
        setIdCarousel(data.id_carousel)
        setUrl(data.url)
        setPosition(data.posisi)
        setExtUrl(data.external_url)
        setDate([
            new Date(data.start_show),
            new Date(data.end_show)
        ])
    }, [])
    
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Edit Carousel Banner</div>
                <div className="text-muted">Edit item image for carousel banner</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={10} md={12} sm={12} className="mb-3">
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Input type="text" label="Title" defaultValue={title} onChange={(e, { value }) => setTitle(value)} required />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input type="text" label="Description" defaultValue={desc} onChange={(e, { value }) => setDesc(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input type="text" label="Url" defaultValue={url} onChange={(e,{value}) => setUrl(value)}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input type="text" label="External Url" defaultValue={extUrl} onChange={(e,{value}) => setExtUrl(value)}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input type="text" label="Position" defaultValue={position} onChange={(e,{value}) => setPosition(value)}/>
                        </Form.Field>
                        <Form.Input
                            label="Image"
                            type="file"
                            icon="image"
                            className="mb-0 mt-3"
                            onChange={(e) => handleImageChange(e)}
                        />
                        <small className="text-muted">Images must be in *.png format and file size no more than 500kb</small>
                        <Form.Field className="mb-3 mt-2">
                            <label>Show Date</label>
                            <DateRangePicker
                                defaultValue={date}
                                format="yyyy-MM-dd HH:mm:ss"
                                appearance="default"
                                size="lg"
                                style={{ width: "100%" }}
                                placement="topStart"
                                onOk={setDate}
                                required
                            />
                        </Form.Field>
                        <Form.Field className="mt-4">
                            <Form.Button content="Update Item" color="facebook" />
                        </Form.Field>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default CarouselBannerEdit