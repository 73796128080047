import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Button, Divider, Form, Popup } from "semantic-ui-react";
import { Toast } from "../component/Toast";

export const Employee = () => {
    const [dataEmploy, setDataEmploy] = useState([])
    const [dataRoleOpt, setDataRoleOpt] = useState([])
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [loadEdit, setLoadEdit] = useState(false)
    const [loadDelete, setLoadDelete] = useState({})
    const [loadAdd, setLoadAdd] = useState(false)
    const [addEmp, setAddEmp] = useState({
        email: null,
        name: null,
        role: null,
        pwd: null,
        cfm_pwd: null,
    })
    const [selRow, setSelRow] = useState({
        email: null,
        name: null,
        role: null,
        pwd: null,
        login: null,
        id_user: null,
    })

    const updateKeyInSelRow = (key, value) => {
        setSelRow(prevState => ({
            ...prevState, // Pertahankan kunci-kunci yang ada
            [key]: value // Update nilai kunci yang ditentukan
        }));
    };

    const updateKeyInSelRowAdd = (key, value) => {
        setAddEmp(prevState => ({
            ...prevState, 
            [key]: value 
        }));
    };

    const getEmployee = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_hoops_cms/controler/admin.php/?index=1`,
        }).
            then(res => {
                if (res.data.state === 200) {
                    setDataEmploy(res.data.data)
                }
            })
    }

    const handleShowModalEdit = (data) => {
        setShowModalEdit(true)
        setSelRow({
            id_user: data.id_user,
            email: data.email,
            name: data.name,
            role: data.id_role,
            login: data.is_log,
            pwd: data.pwd,
        })
    }

    const getRole = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_hoops_cms/controler/admin.php/?index=2`,
        }).
            then(res => {
                if (res.data.state === 200) {
                    setDataRoleOpt(res.data.data.map((item, i) => {
                        return {
                            key: item.id_role,
                            text: item.role_name,
                            value: item.id_role,
                        }
                    })
                    )
                }
            })
    }

    const handleSubmit = () => {
        const param = addEmp
        if (param.pwd === param.cfm_pwd){
            setLoadAdd(true)
            axios({
                method: "POST",
                url: `${global.SRV}api_hoops_cms/controler/admin.php/?index=5`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: param
            })
            .then(res => {
                setLoadAdd(false)
                if (res.data.state === 200){
                    getEmployee()
                    Toast.fire({
                        icon:'success',
                        text:'Success adding new user',
                        position:'top-right'
                    })
                } else {
                    Toast.fire({
                        icon:'error',
                        text:'Failed adding new user',
                        position:'top-right'
                    })
                }
            })
        } else {
            Toast.fire({
                icon:'warning',
                text:'Password did not match...',
                position:'top-right'
            })
        }
    }

    const handleSubmitEdit = () => {
        setLoadEdit(true)
        const param = selRow
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/admin.php/?index=3`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: param
        })
            .then(res => {
                setLoadEdit(false)
                if (res.data.state === 200) {
                    setShowModalEdit(false)
                    getEmployee()
                    Toast.fire({
                        icon: 'success',
                        text: 'Success update employee',
                        position: 'top-right'
                    })
                } else {
                    Toast.fire({
                        icon: 'error',
                        text: 'Failed update employee',
                        position: 'top-right'
                    })
                }
            })
    }

    const handleDeleteEmp = (id) => {
        const param = {
            id_user: id
        }
        const msg = window.confirm('Do you want to delete this Employee ?')
        if (msg) {
            setLoadDelete({
                id:id,
                status:true
            })
            axios({
                method: "POST",
                url: `${global.SRV}api_hoops_cms/controler/admin.php/?index=4`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: param
            })
                .then(res => {
                    setLoadDelete({
                        id:id,
                        state:false
                    })
                    if (res.data.state === 200) {
                        getEmployee()
                        Toast.fire({
                            icon: 'success',
                            text: 'Success remove selected Employee',
                            position: 'top-right',
                        })
                    } else {
                        Toast.fire({
                            icon: 'error',
                            text: 'Failed remove selected Employee',
                            position: 'top-right',
                        })
                    }
                })
        }
    }

    useEffect(() => {
        getEmployee()
        getRole()
    }, [])

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Employee</div>
                <div className="text-muted">This list of created Employee</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center">
                <Col xl={6} lg={6} md={8} sm={12} xs={12} className="mb-5">
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Form.Input
                                    label="Email"
                                    type="email"
                                    icon="at"
                                    className="mb-3"
                                    required
                                    value={addEmp.email}
                                    onChange={(e) => updateKeyInSelRowAdd('email', e.target.value)}
                                />
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Form.Input
                                    label="Employee Name"
                                    type="text"
                                    icon="user outline"
                                    className="mb-3"
                                    required
                                    value={addEmp.name}
                                    onChange={(e) => updateKeyInSelRowAdd('name', e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Form.Select
                            label="Role"
                            options={dataRoleOpt}
                            search
                            required
                            value={addEmp.role}
                            onChange={(e, {value}) => updateKeyInSelRowAdd('role', value)}
                        />
                        <Row className="mb-4">
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Form.Input
                                    type="password"
                                    label="Password"
                                    required
                                    value={addEmp.pwd}
                                    onChange={(e) => updateKeyInSelRowAdd('pwd', e.target.value)}
                                />
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Form.Input
                                    type="password"
                                    label="Confirm Password"
                                    required
                                    onChange={(e) => updateKeyInSelRowAdd('cfm_pwd', e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Button content="Add Employee" icon="plus" color="facebook" loading={loadAdd} disabled={loadAdd}/>
                    </Form>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xl={10} lg={12} md={12} sm={12} xs={12}>
                    <div className="mb-2 fw-bold h5">Registered Employee</div>
                    <table className="table">
                        <thead className="bg-light">
                            <tr>
                                <th>#</th>
                                <th>Email</th>
                                <th>Employee Name</th>
                                <th>Role</th>
                                <th>Login</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataEmploy.map((item, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{item.email}</td>
                                        <td>{item.name}</td>
                                        <td>{item.role_name}</td>
                                        <td>{item.is_log}</td>
                                        <td>
                                            <Button.Group basic size="tiny">
                                                <Popup
                                                    content="Edit Selected Employee"
                                                    trigger={
                                                        <Button icon="edit ouline" onClick={() => (handleShowModalEdit(item))} />
                                                    }
                                                />
                                                <Popup
                                                    content="Delete Selected Employee"
                                                    trigger={
                                                        <Button icon="trash alternate" loading={loadDelete.id === item.id_user && loadDelete.status } disabled={loadDelete.id === item.id_user && loadDelete.status} onClick={(e) => handleDeleteEmp(item.id_user)} />
                                                    }
                                                />
                                            </Button.Group>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>

            <Modal show={showModalEdit} onHide={() => setShowModalEdit(false)} keyboard={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5">Edit Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form widths="equal" onSubmit={handleSubmitEdit}>
                        <Form.Input
                            label="Email"
                            type="email"
                            value={selRow.email}
                            onChange={(e) => updateKeyInSelRow('email', e.target.value)}
                        />
                        <Form.Input
                            label="Employee Name"
                            type="text"
                            value={selRow.name}
                            onChange={(e) => updateKeyInSelRow('name', e.target.value)}
                        />
                        <Form.Select
                            label="Role"
                            options={dataRoleOpt}
                            value={selRow.role}
                            onChange={(e, { value }) => updateKeyInSelRow('role', value)}
                        />
                        <Form.Input
                            label="Password"
                            type="password"
                            onChange={(e) => updateKeyInSelRow('pwd', e.target.value)}
                        />
                        <div className="text-end">
                            <Button
                                className="mt-3"
                                type="submit"
                                content="Apply Change"
                                labelPosition="right"
                                color="facebook"
                                icon="arrow alternate circle right outline"
                                loading={loadEdit}
                                disabled={loadEdit}
                            />
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    )
}