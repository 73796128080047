import React, { useState, useEffect, useRef, Fragment } from "react";
import { useReactToPrint } from 'react-to-print';
import { Col, Container, Row, Card, Stack, Image, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Divider, Button } from "semantic-ui-react";
import axios from "axios";
import Barcode from "react-barcode";
import logo from "../../assets/logo.png"
import logo_jne from "../../assets/jne_logo.png"

export const OrderDelivery = () => {
    const location = useLocation()
    const componentRef = useRef();
    const [idOrder, setIdOrder] = useState(null)
    const [idCustomer, setIdCustomer] = useState(null)
    const [order, setOrder] = useState(null)
    let invNumber = `INV${location.state.id_order.slice(2, location.state.id_order.length)}`

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getOrder = async (id_order, id_customer) => {
        const param = {
            id_order: id_order,
            id_customer: id_customer,
        }
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/order.php/?index=3`,
            headers: { 'Content-Type': 'text/plain' },
            data: param
        })
            .then(res => { console.log(res.data.data)
                if (res.data.state === 200) {
                    setOrder(res.data.data)
                }
            })
    }
   
    const PrintComponent = React.forwardRef((props, ref) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let orderDate = new Date(order.header.order_date)
        let dateFormated = orderDate.toLocaleDateString("en-US", options)
        let orderID = order.header.id_order
        let invNumber = `INV${orderID.slice(2, orderID.length)}`
       
        return (
            <Fragment>
                <div ref={ref} style={{ paddingTop: 50 }}>
                    <div 
                        style={{
                            position:'absolute',
                            top:'16%',
                            left:'10%',
                            zIndex:'auto',
                            letterSpacing:'20px',
                            opacity:'0.1',
                            fontSize:'4em',
                            fontWeight:'bold',
                        }}
                    ><Image src={logo} height={250} width={350}/></div>
                    <Row className="ms-5">
                        <Col lg={12} className="border border-dark w-50">
                            <div>
                                <Row className="border-bottom border-dark">
                                    <Col sm={6} className="mt-2 mb-2">
                                        <Stack direction="horizontal" gap={2}>
                                            <Image src={logo} height={28} width={38}/>
                                            <div className="fw-bold" style={{fontSize:'1em'}}>Hoops Indonesia</div>
                                        </Stack>
                                    </Col>
                                    <Col sm={6} className="mt-2 mb-2">
                                        <div className="text-end" style={{fontSize:'0.9em', paddingTop:'0.2em'}}>Delivery Label</div>
                                    </Col>
                                    
                                </Row>
                                <Row className="border-bottom border-dark">
                                    <Col className="text-center">
                                        <Barcode height={70} width={2.5} value={order.header.no_resi} fontSize={14} displayValue={false} marginBottom={1}/>
                                        <div style={{fontSize:'0.8em'}}>Booking Number : {order.header.no_resi}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3} className="mt-3">
                                        <div><Image src={logo_jne} height={20} width={40}/></div>
                                        
                                    </Col>
                                    <Col sm={3} className="mt-3">
                                        <div style={{fontSize:'0.8em'}}>
                                            <div className="fw-bold" style={{lineHeight:'1.5em'}}>{order.header.name}</div>
                                            <div className="text-facebook fw-bold" style={{lineHeight:'1em'}}>{order.header.service_display}</div>
                                        </div>
                                    </Col>
                                    <Col sm={3} className="mt-3">
                                        <div style={{fontSize:'0.8em'}}>
                                            <div className="text-muted" style={{lineHeight:'1.5em'}}>Order Date</div>
                                            <div className="fw-bold" style={{lineHeight:'1em'}}>{order.header.date_create}</div>
                                        </div>
                                    </Col>
                                    <Col sm={3} className="mt-3 text-end">
                                        <div style={{fontSize:'0.8em'}}>
                                            <div className="text-muted" style={{lineHeight:'1.5em'}}>Invoice number</div>
                                            <div className="fw-bold" style={{lineHeight:'1em'}}>#{invNumber}</div>
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col sm={3}>
                                    </Col>
                                    <Col sm={5} className="mt-1">
                                        <div style={{fontSize:'0.8em'}}>
                                            <div className="text-muted" style={{lineHeight:'1.5em'}}>Shipping cost</div>
                                            <div className="fw-bold" style={{lineHeight:'1em'}}>{global.IDR.format(order.header.courier_cost)}</div>
                                        </div>
                                    </Col>
                                    <Col sm={4} className="mt-1">
                                        <div style={{fontSize:'0.8em'}}>
                                            <div className="text-muted" style={{lineHeight:'1.5em'}}>Weight</div>
                                            <div className="fw-bold" style={{lineHeight:'1em'}}>{order.header.total_weight} Kg</div>
                                        </div>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col sm={3} className="mt-1">
                                        <div className="fw-bold" style={{fontSize:'0.8em'}}>Sent to</div>
                                    </Col>
                                    <Col sm={9} style={{fontSize:'0.8em'}} className="mt-1">
                                        <div className="fw-bold">{order.header.recipient}</div>
                                        <div style={{lineHeight:'1.2em'}}>({order.header.phone})</div>
                                        <div style={{lineHeight:'1.5em'}}>{order.header.address_detail} {order.header.address_detail_dua}</div>
                                        <div style={{lineHeight:'1.5em'}}>{order.header.sub_district}, {order.header.district}, {order.header.city}, {order.header.zip}</div>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-dark pb-2">
                                    <Col sm={3} className="mt-1">
                                        <div className="fw-bold" style={{fontSize:'0.8em'}}>From</div>
                                    </Col>
                                    <Col sm={9} className="mt-1" style={{fontSize:'0.8em'}}>
                                        <div className="fw-bold">Hoops Indonesia</div>
                                        <div style={{lineHeight:'1.2em'}}>Jl. Surapati No.61, Sadang Serang, Coblong,</div>
                                        <div style={{lineHeight:'1.5em'}}>Bandung, Jawa Barat</div>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-dark pb-2">
                                    <Col>
                                        <Table style={{fontSize:'12px'}}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Product</th>
                                                    <th>Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    order.detail.map((o,i) => 
                                                        <tr>
                                                            <td>{i+1}</td>
                                                            <td>
                                                                <Stack direction="horizontal" gap={2}>
                                                                    <Image src={o.image} height={40} width={46} rounded className="border"/>
                                                                    <Stack direction="vertical" gap={0} style={{lineHeight:1.1}}>
                                                                        <div className="fw-bold">{o.item_name}</div>
                                                                        <div>{o.id_product}</div>
                                                                        <div>Size : {o.size}</div>
                                                                    </Stack>
                                                                </Stack>
                                                            </td>
                                                            <td>{o.qty}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mt-1 mb-2">
                                        <div className="fw-bold" style={{fontSize:'0.8em'}}>The booking code above cannot be tracked yet</div>
                                        <div style={{fontSize:'0.8em'}}>
                                            <div style={{lineHeight:'1.2em'}}>
                                                Booking number has not been verified by the system. It cannot be tracked yet, until the courier agent verifies it in their system.
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        )
    })

    useEffect(() => {
        setIdOrder(location.state.id_order)
        setIdCustomer(location.state.id_customer)
        getOrder(location.state.id_order, location.state.id_customer)
    }, [location.state])

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Shipping Label</div>
                <div className="text-muted">This is shipping label for order #{location.state.id_order}</div>
            </Row>
            <Divider />
            <Row className="mb-3">
                <Col lg={6}>

                </Col>
                <Col lg={6} className='d-flex justify-content-end'>
                    <Button content="Print" icon="print" onClick={handlePrint} />
                </Col>
            </Row>
            {
                order ?
                    <PrintComponent ref={componentRef} data={order} />
                    : <>Loading...</>
            }
        </Container>
    )
}
