import React, { useState, useEffect, useRef, Fragment } from "react";
import { useReactToPrint } from 'react-to-print';
import { Col, Container, Row, Card, Stack, Image, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Divider, Button, Icon } from "semantic-ui-react";
import axios from "axios";
import Barcode from "react-barcode";
import logo from '../../assets/logo.png'

const OrderInvoice = () => {
    const location = useLocation()
    const componentRef = useRef();
    const [idOrder, setIdOrder] = useState(null)
    const [idCustomer, setIdCustomer] = useState(null)
    const [order, setOrder] = useState(null)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getOrder = async (id_order, id_customer) => {
        const param = {
            id_order: id_order,
            id_customer: id_customer,
        }
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/order.php/?index=3`,
            headers: { 'Content-Type': 'text/plain' },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    setOrder(res.data.data)
                }
            })
    }
    
    const PrintComponent = React.forwardRef((props, ref) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let orderDate = new Date(order.header.order_date)
        let dateFormated = orderDate.toLocaleDateString("en-US", options)
        let orderID = order.header.id_order
        let invNumber = `INV${orderID.slice(2, orderID.length)}`
        console.log(order)
        return (
            <Fragment>
                <div ref={ref} style={{ paddingTop: 50 }}>
                    <div 
                        style={{
                            position:'absolute',
                            top:'28%',
                            left:'23%',
                            zIndex:'auto',
                            letterSpacing:'40px',
                            transform: "rotate(-35deg)",
                            opacity:'0.1',
                            fontSize:'7em',
                            fontWeight:'bold',
                        }}
                    >{(order.payment.payment_status == 'settlement' || order.payment.payment_status == 'capture') ? "PAID" : "UNPAID"}</div>
                    <Row className="d-flex justify-content-center">
                        <Col sm={10}>
                            <Stack direction="horizontal" gap={2}>
                                <Image src={logo} width={70} height={50} />
                                <div>
                                    <div className="fw-bold">HOOPS INDONESIA</div>
                                    <div style={{ fontSize: '0.7nm' }}>The Biggest Basketball Store in Indonesia.</div>
                                </div>
                            </Stack>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Col sm={10}><Divider/></Col>
                    </Row>
                    <Row className="d-flex justify-content-center mb-3">
                        <Col sm={10} className="d-flex justify-content-end fw-bold text-facebook">INVOICE&nbsp;&nbsp;#{invNumber}</Col>
                        <Col sm={10} className="d-flex justify-content-start mb-3" >
                            <Row className="">
                                <Col sm={3} className="text-muted">Name</Col>
                                <Col sm={8} className="fw-bold">:&nbsp;{order.customer.first_name + ' ' + order.customer.last_name}</Col>
                                <Col sm={3} className="text-muted">Order Date</Col>
                                <Col sm={8} className="fw-bold">:&nbsp;{dateFormated}</Col>
                                <Col sm={3} className="text-muted">Shipping Address</Col>
                                <Col sm={8}>
                                    <div><span className="fw-bold">:&nbsp;{order.header.recipient}</span> ({order.header.phone})</div>
                                    <div>&nbsp;&nbsp;{order.header.address_detail}&nbsp;{order.header.address_detail_dua}</div>
                                    <div>&nbsp;&nbsp;{order.header.sub_district}, {order.header.district}, {order.header.city}, {order.header.zip}</div>
                                    <div>&nbsp;&nbsp;{order.header.province}</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={10} className="mb-3">
                            <Table>
                                <thead>
                                    <tr className="text-facebook">
                                        <th>#</th>
                                        <th>PRODUCT</th>
                                        <th>DISCOUNT</th>
                                        <th>QTY</th>
                                        <th>BASE PRICE</th>
                                        <th>TOTAL PRICE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        order?.detail?.map((o,i) => { console.log('diskon: ', o.discount)
                                            return (
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td>
                                                    
                                                    <Stack direction="horizontal" gap={3}>
                                                        <Image src={o.image} width={58} height={48} rounded/>
                                                        <Stack direction="vertical" gap={0}>
                                                            <div className="fw-bold">{o.product_detail.product_name}</div>
                                                            <div>{o.product_detail.id_product}</div>
                                                            <div>
                                                                <span className="me-3">Size : {o.size}</span>
                                                                <span>Weight : {o.product_detail.weight} Kg</span>
                                                            </div>
                                                        </Stack>
                                                    </Stack>
                                                </td>
                                                <td >
                                                    <div>{parseInt(o.discount) > 0 ? o.discount : parseInt(o.flashsale_discount) > 0 ? o.flashsale_discount : '0'}%</div>
                                                    <div className="text-nowrap text-truncate" style={{color:'coral', fontSize:'0.8em', fontWeight:'bold', width:120}}>{parseInt(o.flashsale_discount) > 0 ? <><Icon name="tag"/>{o.flashsale_name}</> : ''}</div>
                                                </td>
                                                <td>{o.qty}</td>
                                                <td>
                                                    <div>{global.IDR.format(o.price - parseFloat(o.price) * parseInt(o.discount) / 100)}</div>
                                                    {
                                                        parseInt(o.discount) > 0 ?
                                                        <div className="text-muted"><s>{global.IDR.format(o.price)}</s></div>
                                                        : <></>
                                                    }
                                                </td>
                                                <td>{global.IDR.format(o.total_price)}</td>
                                            </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                        <Col sm={6}>
                            <Stack direction="horizontal" gap={1} className="mb-2">
                                <div style={{width:'35%'}} className="fw-bold">TOTAL PRICE ({order.detail.length} ITEM)</div>
                                <div className="fw-bold">:&nbsp;{global.IDR.format(order.header.total_price)}</div>
                            </Stack>
                            <Stack direction="horizontal" gap={1}>
                                <div style={{width:'35%'}}>Shipping cost ({order.header.total_weight} Kg)</div>
                                <div>
                                    :&nbsp;{global.IDR.format(order.header.courier_cost)}&nbsp;
                                    ({global.IDR.format(parseFloat(order.header.courier_cost) + parseFloat(order.header.shipping_voucher))}) &nbsp;
                                    <span className="text-muted fw-bold">{order.header.name} - {order.header.service_display}</span>
                                </div>
                            </Stack>
                            <Stack direction="horizontal" gap={1}>
                                <div style={{width:'35%'}}>Shipping Insurance.</div>
                                <div>:&nbsp;{global.IDR.format(order.header.asuransi)}</div>
                            </Stack>
                            <Stack direction="horizontal" gap={1}>
                                <div style={{width:'35%'}}>Total shipping Disc.</div>
                                <div>:&nbsp;{global.IDR.format(order.header.shipping_voucher)}</div>
                            </Stack>
                            <Stack direction="horizontal" gap={1}>
                                <div style={{width:'35%'}}>Total voucher</div>
                                <div style={{color:'coral', fontWeight:'bold'}}>:&nbsp;{global.IDR.format(order.header.total_voucher)}</div>
                            </Stack>
                            <Stack direction="horizontal" gap={1} className="mb-2 mt-2">
                                <div style={{width:'35%'}} className="fw-bold">TOTAL INVOICE</div>
                                <div className="fw-bold">:&nbsp;{global.IDR.format(order.header.total_payment)}</div>
                            </Stack>
                        </Col>
                        <Col sm={4}>
                            <div className="fw-bold mb-2">COURIER :</div>
                            <Stack direction="horizontal" gap={2} className="mb-3">
                                <div className="text-start" style={{border:'solid 1px lightgrey', padding:'2px'}}><Image src={order.header.logo} height={40} width={40}/></div>
                                <Stack direction="vertical" gap={0}>
                                    <div className="fw-bold">{order.header.name}</div>
                                    <div className="text-facebook fw-bold">Service type : {order.header.service_display}</div>
                                </Stack>
                            </Stack>
                            <div className="fw-bold">NO RESI :</div>
                            {/* <div className="fw-bold text-facebook">{order.header.no_resi}</div> */}
                            {
                            order.header.no_resi ?
                            <Barcode height={40} value={order.header.no_resi}/>
                            : <p className="text-muted">Not yet available</p>
                            }   
                        </Col>
                        <Col sm={10}>
                                <div style={{width:'25%'}}>Payment Method</div>
                                <div className="fw-bold text-uppercase">{order.payment.payment_method} - {order.payment.bank}</div>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center mt-5 mb-3">
                        <Col sm={6} style={{ width: '84%'}}>
                            <Row>
                                <Col sm={6} style={{fontSize:"small"}}>This invoice is valid and processed by a digital system</Col>
                                <Col sm={6} className="d-flex justify-content-end" style={{fontSize:"small"}}><i>Last update : {order.header.date_update} WIB</i></Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        )
    })

    useEffect(() => {
        setIdOrder(location.state.id_order)
        setIdCustomer(location.state.id_customer)
        getOrder(location.state.id_order, location.state.id_customer)
    }, [location.state])

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Invoice</div>
                <div className="text-muted">This is invoice for order #{location.state.id_order}</div>
            </Row>
            <Divider />
            <Row className="mb-3">
                <Col lg={6}>

                </Col>
                <Col lg={6} className='d-flex justify-content-end'>
                    <Button content="Print" icon="print" onClick={handlePrint} />
                </Col>
            </Row>
            {
                order ?
                    <PrintComponent ref={componentRef} data={order} />
                    : <>Loading...</>
            }
        </Container>
    )
}

export default OrderInvoice