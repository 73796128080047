import React, {useState, useEffect} from "react";
import { Divider, Form } from "semantic-ui-react";
import { Container, Col, Row } from "react-bootstrap";
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Toast } from "../component/Toast";

const FlashsaleEdit = () => {
    const navigate = useNavigate()
    const location = useLocation().state
    const [dataForm, setDataForm] = useState({})
    const [date, setDate] = useState([new Date(location.date_start), new Date(location.date_end)])
    const [idFs, setIdFs] = useState(location.id_fs)
    const [name, setName] = useState(location.name)
    const [description, setDescription] = useState(location.description)
    const [discount, setDiscount] = useState(location.discount)
    const [active, setActive] = useState(location.is_active)
  
    const handleSubmit = () => { 
        let data = new URLSearchParams()
        data.append('id_fs', idFs)
        data.append('name' , name)
        data.append('description', description)
        data.append('active_date', JSON.stringify(date))
        data.append('active', active)
        
        axios ({
            method:"POST",
            url:`${global.SRV}api_hoops_cms/controler/event.php/?index=6`,
            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
            data:data
        }).
        then(res => { 
            if (res.data.state === 200){
                Toast.fire({
                    icon:"success",
                    text: res.data.message,
                    position:"top-end"
                })
                navigate('/flashsale')
            }
        })
    }

    const handleCheckbox = (checked) => { 
        if (checked){
            setActive('Y')
        }else{
            setActive('N')
        }
    }


   
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Edit Flashsale</div>
                <div className="text-muted">Here you can edit flashsale</div>
            </Row>
            <Divider />

            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={12} md={12} sm={12}>
                    <Form widths="equal" onSubmit={() => handleSubmit()}>
                        <Form.Input label="Flashsale Name" defaultValue={name} onChange={(e) => setName(e.target.value)} required/>
                        <Form.Input label="Description" defaultValue={description} onChange={(e) => setDescription(e.target.value)}/>
                        {/* <Form.Input label="Discount Percent" type="number" defaultValue={dataForm.discount} onChange={(e) => setDataForm({...dataForm, discount:e.target.value})} required/> */}
                        <Form.Field className="mb-3">
                            <label>Active Date</label>
                            <DateRangePicker 
                                value={date}
                                format="yyyy-MM-dd HH:mm:ss"     
                                appearance="default" 
                                size="lg" 
                                style={{width:"100%"}} 
                                placement="bottomStart"
                                onOk={setDate}
                                required
                            />
                        </Form.Field>
                        <Form.Checkbox inline label= "Active" defaultChecked={active === 'Y' ? true : false} onChange={(e, data) => handleCheckbox(data.checked)}/>
                        <Form.Field className="mb-3 mt-5">
                            <Form.Button content="Save Flashsale" color="facebook" />
                        </Form.Field>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default FlashsaleEdit