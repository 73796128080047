import React, { Fragment, useEffect, useMemo, useState } from "react";
import Table from "../component/Table";
import axios from "axios";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Button, Checkbox, Divider, Dropdown, Popup } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { Bs0Circle, BsImage } from "react-icons/bs";
import Pesan from "../component/Pesan";
import AGtable from "../component/AGTable";
import Toast from "../component/Toast"
import Swal from "sweetalert2"

const Product = () => {
    const navigate = useNavigate();
    const [datas, setDatas] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [loading, setLoading] = useState(true);
    const [checkedMap, setCheckedMap] = useState({});
    const [disabledBtn, setDisabledBtn] = useState({})
    const [loadXml, setLoadXml] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    

    const getData = async () => {
        await axios.get(`https://hoopsdevteam.com/api_web/controler/cms.php/?index=2`)
            .then(res => { 
                const initialCheckedMap = {};
                const initialButtonMap = {}
                if (res.data.state === 200) {
                    setDatas(res.data.data)
                    res.data.data.forEach((row) => {
                        initialCheckedMap[row.id_product] = row.is_active === '1';
                    });
                    res.data.data.forEach((row) => {
                        initialButtonMap[row.id_product] = row.is_new === 'Y';
                    });
                    setCheckedMap(initialCheckedMap);
                    setDisabledBtn(initialButtonMap)
                    setLoading(false)
                }
            })
    }
    const [selectedRow, setSelectedRow] = useState(null);
   
    const data = useMemo(() => datas, [datas])
    const columns = useMemo(() => [
        {
            Header: "Image",
            accessor: "image",
            Cell: ({ cell }) => (
                (cell.value) ? <Image src={cell.value} width={50} fluid rounded /> : <Image src="https://via.placeholder.com/500x400.png" width={50} fluid rounded />
            )
        },
        {
            Header: "ID",
            accessor: "id_product",
            filter: "text"
        },
        {
            Header: "Name",
            accessor: "product_name",
            filter: 'text',
        },
        {
            Header: "Active",
            accessor: "is_active",
            filter: true,
            Cell: ({ cell }) => (
                <Checkbox
                    toggle
                    checked={cell.value == '1' ? true : false}
                    style={{ transform: 'scale(.7)' }}
                    onChange={(e) => handleChangeDisplayOne(e, cell.row.values)}
                />
            )
        },
        {
            Header: "Date Add",
            accessor: "date_add"
        },
        {
            Header: "Qty",
            accessor: "current_qty",
            filter: "text"
        },
        {
            Header: "Action",
            accessor: "act",
            filter: false,
            Cell: ({ cell }) => {
                return (
                    <Fragment>
                        <Dropdown
                            floating
                            labeled
                            className='icon'
                            icon="ellipsis vertical"
                        >
                            <Dropdown.Menu className="left">
                                <Dropdown.Item icon="images outline" text="Image" onClick={() => handleClickActionImage(cell.row.original)} />
                                <Dropdown.Item icon="tasks" text="Attribute" onClick={() => handleClickActionAttribute(cell.row.original)} />
                                <Dropdown.Item icon="edit" text="Edit" onClick={() => handleClickActionEdit(cell.row.original)} />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Fragment>
                )
            }
        }
    ], [])

    const handleChangeDisplay = (idProduct) => {
        updateState(idProduct)
    }

    const handleChangeDisplayOne = (e, data) => {
        updateState(data.id_product)
    }

    const updateState = (id) => {
        let data = {
            id_product: id
        }
        axios.post("https://hoopsdevteam.com/api_web/controler/cms.php/?index=16", data)
            .then(res => {
                if (res.data.state === 200) {
                    // getData()
                    Toast.fire({
                        icon:"success",
                        text : "Product status changed",
                        position: "top-end",
                    })
                } else {
                    Toast.fire({
                        icon:"error",
                        text : res.data.error,
                        position: "top-end",
                    })
                }
            })
    }

    const handleClickProductAdd = () => (navigate('/product_add'))

    const handleClickActionImage = (row) => {
        setSelectedRow(row)
        navigate('/product_image', { state: { selected: row } })
    }

    const handleClickActionAttribute = (row) => {
        axios.post("https://hoopsdevteam.com/api_web/controler/cms.php/?index=19", { id_product: row.id_product })
            .then(res => {
                setSelectedRow(row)
                if (res.data.state === 200) {
                    navigate('/product_attribute', { state: { selected: row } })
                }

                if (res.data.state === 300) {
                    navigate('/product_attribute_edit', { state: { selected: row } })
                }
            })
    }

    const handleClickActionEdit = (row) => {
        setSelectedRow(row)
        navigate('/product_edit', { state: { selected: row } })
    }

    const handleClickActionRemNew = (row) => {
        const data = new URLSearchParams()
        const productId = row.id_product;
        data.append('id_product', row.id_product)
        data.append('status', 'N')
        axios.post("https://hoopsdevteam.com/api_web/controler/product.php/?index=20", data)
        .then(res => {
            if ( res.data.state === 200){
                Toast.fire({
                    icon:'success',
                    text : "The product has been released from the new product group",
                    position: "top-end",
                })
            } else {
                Toast.fire({
                    icon:'error',
                    text : "Failed to change product",
                    position: "top-end",
                })
            }
        })
        setDisabledBtn((prevButtonMap) => ({
            ...prevButtonMap,
            [productId]: !prevButtonMap[productId],
        }));
    }

    const handleClickActionRemNewBack = (row) => {
        const data = new URLSearchParams()
        const productId = row.id_product;
        data.append('id_product', row.id_product)
        data.append('status', 'Y')
        axios.post("https://hoopsdevteam.com/api_web/controler/product.php/?index=20", data)
        .then(res => {
            if ( res.data.state === 200){
                Toast.fire({
                    icon:'success',
                    text : "The product has been added to a new product group",
                    position: "top-end",
                })
            } else {
                Toast.fire({
                    icon:'error',
                    text : "Failed to change product",
                    position: "top-end",
                })
            }
        })
        setDisabledBtn((prevButtonMap) => ({
            ...prevButtonMap,
            [productId]: !prevButtonMap[productId],
        }));
    }
    
    const rederedButton = (params) => {
        const productId = params.data.id_product
        return (
            <Fragment>
                <Button.Group basic size="mini">
                    <Popup content="Add Image" size="small" trigger={
                        <Button icon="image" onClick={() => handleClickActionImage(params.data)} />
                    }/>
                    <Popup content="Add Attribute" size="small" trigger={
                        <Button icon="tasks" onClick={() => handleClickActionAttribute(params.data)} />
                    }/>
                    <Popup content="Edit product" size="small" trigger={
                        <Button icon="edit" onClick={() => handleClickActionEdit(params.data)} />
                    }/>
                    { 
                        (disabledBtn[productId]) ?
                            <Popup content="Remove product from New" size="small" trigger={
                                <Button icon="eraser" onClick={() => handleClickActionRemNew(params.data)} />
                            }/>
                        : 
                            <Popup content="Set back to New if stay in period" size="small" trigger={
                                <Button icon="undo" onClick={() => handleClickActionRemNewBack(params.data)} />
                            }/>
                    }
                </Button.Group>
            </Fragment>
        )
    }


    // Fungsi yang akan dipanggil ketika terjadi perubahan pada Checkbox
    const handleCheckboxChange = (params) => {
        const productId = params.data.id_product;
        handleChangeDisplay(productId)
        // Mengupdate status checked untuk baris yang sesuai
        setCheckedMap((prevCheckedMap) => ({
            ...prevCheckedMap,
            [productId]: !prevCheckedMap[productId],
        }));
    };

    const renderedCheck = (params) => {
        const productId = params.data.id_product;
        
        return (
            <Checkbox
                checked={checkedMap[productId] || false}
                id={productId}
                onChange={() => handleCheckboxChange(params)}
            />
        );
    };

    const rendererImage = (params) => {
        const image = params.data.image
        return (
            <div style={{width: 45, height:40}}>
            <Image src={image ? image : 'https://via.placeholder.com/500x400.png'} alt={params.data.id_product} rounded fluid/>
            </div>
        )
    }

    const columnDefs = [
        {   
            headerName: 'Image',
            field: 'image',
            cellRenderer:rendererImage, 
            suppressMenu:true,
            cellStyle: { display: 'flex', alignItems: 'center' },
        },
        { headerName: 'Product ID', field: 'id_product' },
        { headerName: 'Product Name', field: 'product_name' },
        { headerName: 'Qty', field: 'current_qty' },
        { headerName: 'Size Group', field: 'size_group' },
        {
            headerName: 'Active',
            field: 'is_active',
            cellRenderer: renderedCheck,
            cellStyle: { display: 'flex', alignItems: 'center' },
        },
        { headerName : 'Date Add', field: 'date_add'},
        {
            headerName: 'Action',
            field : 'action',
            cellRenderer: rederedButton,    
            suppressMenu: true,
            cellStyle: { display: 'flex', alignItems: 'center' },

        }
    ];

    const handleCreatePixel = () => {
        setLoadXml(true)
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/product.php/?index=2`,
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(res => { 
            if (res.data.state === 200){
                setLoadXml(false)
                window.location.href = res.data.url
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Container fluid>
            <Row>
                <div className="text-facebook h3 mb-0">Product list</div>
                <span className="text-muted">list of products that have been added to the website</span>
            </Row>
            <Divider />
            <Row>
                <Col lg={12}>
                    <Pesan variant="success" show={showAlert} setShowAlert={setShowAlert} content="update product state success" header="Success" />
                </Col>
            </Row>
            <Row className="mb-3 pt-3">
                <Col md={12} className="d-flex justify-content-start">
                    <Button content="Add Product" icon="plus" color="facebook" onClick={handleClickProductAdd} />
                    <Button content="Update Product xml for Pixel" onClick={() => handleCreatePixel()} color="blue" loading={loadXml}/>
                </Col>
            </Row>
            <Row>
                <Col md={12} xl={12} sm={12}>
                    {/* <Table columns={columns} data={data} /> */}
                    <AGtable 
                    rowData={data ? data : null} 
                    columnDefs={columnDefs} 
                    loading={loading}
                    exportFileName="data_product"
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default Product;