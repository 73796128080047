import React from "react";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Form, Button } from "semantic-ui-react";
import moment from "moment/moment";
import axios from "axios"
import { DateRangePicker } from "rsuite";

const VoucherSpecialEdit = () => {
    const location = useLocation()
    const navigate = useNavigate()     
    const [idVoucher, setIdVoucher] = useState('')
    const [customerOpt, setCustomerOpt] = useState([])
    const [idCustomer, setIdCustomer] = useState('')
    const [voucherName, setVoucherName] = useState('')
    const [voucherDesc, setVoucherDesc] = useState('')
    const [voucherCode, setVoucherCode] = useState('')
    const [discountType, setDiscountType] = useState('')
    const [discountValue, setDiscountValue] = useState(0)
    const [discountPercent, setDiscountPercent] = useState(0)
    const [discountMax, setDiscountMax] = useState(0)
    const [minTrx, setMinTrx] = useState(0)
    const [expose, setExpose] = useState('N')
    const [combine, setCombine] = useState('N')


    const [date, setDate] = useState([
        new Date(moment()),
        new Date(moment().add(7,'days'))
    ])
    
    const handleCheckExpose = (e) => {
        if (e.checked){
            setExpose('Y')
        }else{
            setExpose('N')
        }
    }

    const handleCheckCombine = (e) => { 
        if (e.checked){
            setCombine('Y')
        }else{
            setCombine('N')
        }
    }

     const type = [
        {key:'P', text:'Percent', value:'P'},
        {key:'N', text:'Nominal', value:'N'},
        {key:'O', text:'Ongkir', value:'O'}
    ]

    const handleSubmit = () => {
        const param = {
            id_voucher: idVoucher,
            id_customer:idCustomer,
            voucher_name:voucherName,
            voucher_desc:voucherDesc,
            voucher_code:voucherCode,
            dsc_type:discountType,
            dsc_value:discountValue,
            dsc_percent:discountPercent,
            dsc_max:discountMax,
            min_trx:minTrx,
            expose:expose,
            is_combine:combine,
            date_range:date
        }

        axios({
            method:"POST",
            url:`${global.SRV}api_hoops_cms/controler/voucher.php/?index=6`,
            headers:{'Content-Type': 'text/plain'},
            data:param
        })
        .then(res => { console.log(res.data)
            if (res.data.state === 200){
                navigate('/voucher_special')
            }
        })
    }

    const getCustomer = async () => {
        await axios.get(`${global.SRV}api_hoops_cms/controler/customer.php/?index=1`)
        .then (res => {
            if (res.data.state === 200){
                setCustomerOpt(res.data.data.map(o => ({
                    key:o.id_customer,
                    text:o.email,
                    value:o.id_customer
                }) ))
            }
        })
    }

    useEffect(() => {
        const data = location.state
        getCustomer()
        setIdVoucher(data.id_voucher)
        setIdCustomer(data.id_customer)
        setVoucherName(data.voucher_name)
        setVoucherDesc(data.voucher_desc)
        setVoucherCode(data.voucher_code)
        setDiscountType(data.discount_type)
        setDiscountValue(data.discount_value)
        setDiscountPercent(data.discount_percent)
        setDiscountMax(data.discount_max)
        setMinTrx(data.minimum_trx)
        setExpose(data.show)
        setCombine(data.is_combine)
        setDate([
            new Date(data.start_date),
            new Date(data.end_date)
        ])

    },[location.state])
    console.log(location.state)
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0"> Edit Special Voucher</div>
                <div className="text-muted">Edit selected special voucher</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={8} md={12} sm={12}>
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Select
                                label="Customer by Email"
                                value={idCustomer}
                                options={customerOpt ? customerOpt : []}
                                onChange={(e, { value }) => setIdCustomer(value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Voucher Name" defaultValue={voucherName} onChange={(e, { value }) => setVoucherName(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Description" defaultValue={voucherDesc} onChange={(e, { value }) => setVoucherDesc(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Voucher Code" defaultValue={voucherCode} onChange={(e, { value }) => setVoucherCode(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Select
                                label="Discount Type"
                                options={type}
                                value={discountType}
                                onChange={(e, { value }) => setDiscountType(value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Discount Fixed" type="number" value={discountValue} onChange={(e, { value }) => setDiscountValue(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Discount Percent" type="number" value={discountPercent} onChange={(e, { value }) => setDiscountPercent(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Discount Max" type="number" value={discountMax} onChange={(e, { value }) => setDiscountMax(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label="Minimum Transaction" type="number" value={minTrx} onChange={(e, { value }) => setMinTrx(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Checkbox label="Expose" defaultChecked={location.state.show === 'N' ? false : true} onChange={(e,data) => handleCheckExpose(data)}/>
                            <Form.Checkbox label="Combinable" defaultChecked={location.state.is_combine === 'N' ? false : true} onChange={(e, data) => handleCheckCombine(data)}/>
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <label>Active Date</label>
                            <DateRangePicker
                                value={date}
                                format="yyyy-MM-dd HH:mm:ss"
                                appearance="default"
                                size="lg"
                                style={{ width: "100%" }}
                                placement="topStart"
                                onOk={setDate}
                            />
                        </Form.Field>
                        <Form.Field className="mb-3 mt-5">
                            <Form.Button content="Save Voucher" color="facebook" />
                        </Form.Field>
                    </Form>

                </Col>
            </Row>
        </Container>
    )
}

export default VoucherSpecialEdit