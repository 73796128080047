import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { Button, Dimmer, Divider, Form, Icon, Loader, Popup } from "semantic-ui-react";
import Table from "../component/Table";
import axios from "axios";
import { DateRangePicker, Modal } from "rsuite";
import { Toast } from "../component/Toast";

export const MemberOld = () => {
    const [dataMember, setDataMember] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const [modalUse, setModalUse] = useState(false)
    const [selData, setSelData] = useState(null)
    const [selDataUse, setSelDataUse] = useState(null)
    const [pointGet, setPointGet] = useState(0)
    const [pointReedem, setPointReedem] = useState(0)
    const [trxValue, setTrxValue] = useState(0)
    const [trxValueUse, setTrxValueUse] = useState(0)
    const [trxNumber, setTrxNumber] = useState('')
    const [trxNumberUse, setTrxNumberUse] = useState('')
    const [trxDate, setTrxDate] = useState('')
    const [trxDateUse, setTrxDateUse] = useState('')
    const [selStore, setSelStore] = useState('')
    const [selStoreUse, setSelStoreUse] = useState('')
    const [loadAdd, setLoadAdd] = useState(false)
    const [loadUse, setLoadUse] = useState(false)
    const [pointHis, setPointHis] = useState([])
    const [modalHis, setModalHis] = useState(false)
    const [loadHis, setLoadHis] = useState(false)
    const [store, setStore] = useState([
        { key: 1, text: 'HOOPS HOMECOURT', value: 'HOOPS HOMECOURT' },
        { key: 2, text: 'HOOPS 23 PASKAL', value: 'HOOPS 23 PASKAL' },
        { key: 3, text: 'HOOPS GRAND INDONESIA', value: 'HOOPS GRAND INDONESIA' },
        { key: 4, text: 'HOOPS SENAYAN CITY', value: 'HOOPS SENAYAN CITY' },
        { key: 5, text: 'HOOPS GROGOL', value: 'HOOPS GROGOL' },
        { key: 6, text: 'HOOPS MKG', value: 'HOOPS MKG' },
        { key: 7, text: 'HOOPS CENTRAL PARK', value: 'HOOPS CENTRAL PARK' },
        { key: 8, text: 'HOOPS KARAWACI', value: 'HOOPS KARAWACI' },
        { key: 9, text: 'HOOPS SUM. SERPONG', value: 'HOOPS SUM SERPONG' },
        { key: 10, text: 'HOOPS GALERIA', value: 'HOOPS GALERIA' },
        { key: 11, text: 'HOOPS LIVING WORLD', value: 'HOOPS LIVING WORLD' },
        { key: 12, text: 'HOOPS PAKUWON', value: 'HOOPS PAKUWON' },
        { key: 13, text: 'HOOPS GALAXY', value: 'HOOPS GALAXY' },
        { key: 14, text: 'HOOPS TUNJUNGAN PLAZA', value: 'HOOPS TUNJUNGAN PLAZA' },
    ])

    const columnsHis = useMemo(() => [
        {
            Header: "Trx Date",
            accessor: "transaction_date"
        },
        {
            Header: "Trx Code",
            accessor: "transaction_number",
            Cell:({cell}) => (
                <div style={{width:'100px'}}>
                    <Popup
                        size="mini"
                        content={cell.value}
                        trigger={<div className="text-truncate">{cell.value}</div>}
                    />
                </div>
            )
        },
        {
            Header: "Trx Total",
            accessor: "transaction_value",
            Cell : ({cell}) => (
                <div>
                    {global.IDR.format(cell.value)}
                </div>
            )
        },
        {
            Header: "Point",
            accessor: "point_value",
            Cell : ({cell}) => (
                <div>
                    <span><Icon name="bitcoin" color="teal"/></span>
                    <span>{cell.value}</span> 
                </div>
            )
        },
        {
            Header: "Outlet",
            accessor: "outlet_id",
            Cell : ({cell}) => (
                <div style={{width:'100px'}}>
                    <Popup 
                        size="mini"
                        content={cell.value}
                        trigger= {<div className="text-truncate">{cell.value}</div>}
                    />
                </div>
            )
        },
        {
            Header: "Type",
            accessor: "type",
            Cell:({cell}) => (
                <div>
                    <span>{cell.row.original.type == 'increase' ? <Icon name="plus circle" color="green"/> : <><Icon name="gift" color="blue"/></>}</span>
                    <span>{cell.row.original.type == 'increase' ? 'Adding' : 'Reedemed'}</span>
                </div>
            )
        },
    ])

    const columns = useMemo(() => [
        {
            Header: "Id_Member",
            accessor: "id"
        },
        {
            Header: "Id_Customer",
            accessor: "idcustomer"
        },
        {
            Header: "Customer",
            accessor: "email",
            Cell: ({ cell }) => (
                <Stack direction="vertical" gap={0}>
                    <div className="fw-bold"><span>{cell.row.original.firstname}</span>&nbsp;<span>{cell.row.original.lastname}</span></div>
                    <div style={{ fontSize: '0.8em' }}>{cell.row.original.email}</div>
                </Stack>
            )
        },
        {
            Header: "Identity",
            accessor: "iden_number",
            Cell: ({ cell }) => (
                <Stack direction="vertical" gap={0}>
                    <div className="fw-bold">{cell.row.original.identity}</div>
                    <div>{cell.row.original.iden_number}</div>
                </Stack>
            )
        },
        {
            Header: "Address",
            accessor: "city",
            Cell: ({ cell }) => (
                <Stack direction="vertical" gap={0}>
                    <div className="fw-bold">{cell.row.original.city}</div>
                    <div style={{ width: '250px', fontSize: '0.8em' }} className="text-truncate">{cell.row.original.address}</div>
                </Stack>
            )
        },
        {
            Header: "Phone",
            accessor: "phone",
            Cell:({cell}) => (
                <div>
                    <span><Icon name="phone"/></span>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Current Point",
            accessor: "pointstate",
            Cell:({cell}) => (
                <div>
                    <span><Icon name="bitcoin" color="teal"/></span>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Point Used",
            accessor: "pointused",
            Cell : ({cell}) => (
                <div>
                    <span><Icon name="gift" color="blue"/></span>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup
                        size="small"
                        content="Click here to add point"
                        trigger={
                            <Button icon="plus circle" onClick={() => handeClickAdd(cell.row.original)} />
                        }
                    />
                    <Popup
                        size="small"
                        content="Click here to reedem point"
                        trigger={
                            <Button icon="gift" onClick={() => handleClickReedem(cell.row.original)} />
                        }
                    />
                    <Popup
                        size="small"
                        content="Point histories"
                        trigger={
                            <Button icon="history" onClick={() => handleClickHis(cell.row.original)} />
                        }
                    />
                </Button.Group>
            )
        }
    ])

    const getPointHistori = async (id) => {
        setLoadHis(true)
        await axios({
            method: "GET",
            url: `${global.SRV}api_hoops_cms/controler/memberOld.php/?index=3&id_member=${id}`,
        })
            .then(res => {
                setLoadHis(false)
                if (res.data.state === 200) {
                    setPointHis(res.data.data)
                }
            })
    }

    const getDataMember = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_hoops_cms/controler/memberOld.php/?index=1`,
        })
            .then(res => {
                if (res.data.state === 200) {
                    setDataMember(res.data.data)
                }
            })
    }

    const handleClickHis = (data) => {
        setSelData(data)
        setModalHis(true)
        getPointHistori(data.id)
    }

    const handeClickAdd = (data) => {
        setSelData(data)
        setModalAdd(true)
    }

    const handleClickReedem = (data) => {
        setSelDataUse(data)
        setModalUse(true)
    }

    const handleChangeTotalTrx = (value) => {
        let point = value * 1 / 100
        setTrxValue(value)
        setPointGet(point)
    }

    const handleSubmitReedem = () => {
        setLoadUse(true)
        const param = {
            id: selDataUse.id,
            customer_id: selDataUse.idcustomer,
            t_number: trxNumberUse,
            t_date: trxDateUse,
            t_value: trxValueUse,
            outlet_id: selStoreUse,
            type_point: 'decrease',
            point: pointReedem,
        }
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/memberOld.php/?index=2`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: param
        })
            .then(res => {
                setLoadUse(false)
                if (res.data.state === 200) {
                    Toast.fire({
                        icon: 'success',
                        text: 'Success reedem point',
                        position: 'top-right'
                    })
                    setModalUse(false)
                    clearForm()
                } else {
                    Toast.fire({
                        icon: 'error',
                        text: 'Failed reedem point',
                        position: 'top-right'
                    })
                }
            })
    }

    const handleSubmit = () => {
        setLoadAdd(true)

        const param = {
            id: selData.id,
            customer_id: selData.idcustomer,
            t_number: trxNumber,
            t_date: trxDate,
            t_value: trxValue,
            outlet_id: selStore,
            type_point: 'increase',
        }
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops_cms/controler/memberOld.php/?index=1`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: param
        })
            .then(res => {
                setLoadAdd(false)
                if (res.data.state === 200) {
                    Toast.fire({
                        icon: 'success',
                        text: 'Success adding point',
                        position: 'top-right'
                    })
                    setModalAdd(false)
                    clearForm()
                } else {
                    Toast.fire({
                        icon: 'success',
                        text: 'Success adding point',
                        position: 'top-right'
                    })
                }
            })
    }

    const clearForm = () => {
        setSelData(null)
        setSelDataUse(null)
        setSelStore('')
        setTrxNumber('')
        setTrxDate('')
        setTrxValue(0)
        setPointGet(0)

        setSelStoreUse('')
        setSelStore('')
        setTrxNumberUse('')
        setTrxDateUse('')
        setTrxValueUse(0)
        setPointReedem(0)
    }

    useEffect(() => {
        getDataMember()
    }, [])

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Member</div>
                <div className="text-muted">This list of Member from old member system</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center">
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-3" style={{ fontSize: '0.9em' }}>
                    <Table data={dataMember} columns={columns} pageRow={10} />
                </Col>
            </Row>

            <Modal backdrop='static' keyboard={false} open={modalAdd} onClose={() => setModalAdd(false)}>
                <Modal.Header>
                    <Modal.Title className="fs-5">Adding Point</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '0.9em' }}>
                    <div className="fw-bold">{selData?.firstname} {selData?.lastname}</div>
                    <div style={{ fontSize: '0.9em' }} className="mb-3">{selData?.email}</div>
                    <Form widths="equal" style={{ fontSize: '0.9em' }} onSubmit={handleSubmit}>
                        <Form.Select
                            search
                            label="Store"
                            options={store}
                            onChange={(e, { value }) => setSelStore(value)}
                            required
                        />
                        <Form.Input label="Transaction Date" type="date" value={trxDate} onChange={(e) => setTrxDate(e.target.value)} required />
                        <Form.Input type="text" label="Transaction Code" icon="keyboard outline" value={trxNumber} onChange={(e) => setTrxNumber(e.target.value)} required />
                        <Form.Input label="Total Transaction" icon="shopping cart" value={trxValue} type="number" onChange={(e) => handleChangeTotalTrx(e.target.value)} required />
                        <Form.Input label="Point Get" icon="bitcoin" value={pointGet} type="number" readonly />
                        <div className="text-end mt-4">
                            <Button type="submit" content="Add Point" icon="plus circle" color="facebook" loading={loadAdd} disabled={loadAdd} />
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal backdrop='static' keyboard={false} open={modalUse} onClose={() => setModalUse(false)}>
                <Modal.Header>
                    <Modal.Title className="fs-5">Reedem Point</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '0.9em' }}>
                    <div className="fw-bold">{selDataUse?.firstname} {selDataUse?.lastname}</div>
                    <div style={{ fontSize: '0.9em' }} className="mb-3">{selDataUse?.email}</div>
                    <Form widths="equal" style={{ fontSize: '0.9em' }} onSubmit={handleSubmitReedem}>
                        <Form.Select
                            search
                            label="Store"
                            options={store}
                            onChange={(e, { value }) => setSelStoreUse(value)}
                            required
                        />
                        <Form.Input label="Transaction Date" type="date" value={trxDateUse} onChange={(e) => setTrxDateUse(e.target.value)} required />
                        <Form.Input type="text" label="Transaction Code" icon="keyboard outline" value={trxNumberUse} onChange={(e) => setTrxNumberUse(e.target.value)} required />
                        <Form.Input label="Total Transaction" icon="shopping cart" value={trxValueUse} type="number" onChange={(e) => setTrxValueUse(e.target.value)} required />
                        <Form.Input label="Point Reedemed" icon="bitcoin" value={pointReedem} onChange={(e) => setPointReedem(e.target.value)} type="number" readonly />
                        <div className="text-end mt-4">
                            <Button type="submit" content="Reedem Point" icon="gift" color="facebook" loading={loadUse} disabled={loadUse} />
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal open={modalHis} onClose={() => setModalHis(false)} size="md" overflow={false}>
                <Modal.Header>
                    <Modal.Title className="fs-5">Point Histories</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '0.8em' }}>
                    <div className="fs-6 fw-bold">{selData?.firstname}&nbsp;{selData?.lastname}</div>
                    <div>{selData?.email}</div>
                    <Table data={pointHis} columns={columnsHis} pageRow={10} />
                    <Dimmer active={loadHis} inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                </Modal.Body>
            </Modal>
        </Container>
    )
}